import React, { useEffect, useMemo, useState } from 'react';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { toast } from 'react-toastify';
import { EditOrderInitialValueUtils } from '../utils/OrderInitialValue.utils';
import { AddOrderFormSchema } from '../@type/AddOrderForm.schema';
import orderService from '../../../services/order/Order.service';
import productService from '../../../services/product/Product.service';
import { DownloadIcon, SearchIcon, TrashIcon } from '../../../Icon';
import Papa from 'papaparse';
import { OrderInfoEditForm } from '../@type/OrderInfoEdit.type';
import { Order, ProductOrder } from '../../../@types/Order.type';
import ProductsOrderCost from './ProductsOrderCost';
import supplierService from '../../../services/supplier/Supplier.service';
import { Supplier } from '../../../@types/Supplier.type';
import GlobalQuantity from './GlobalQuantity';
import GlobalCost from './GlobalCost';

interface OrderInfoProps {
  onClose: () => void;
  orderId: string;
}
const ShowMoreOrderInfo: React.FC<OrderInfoProps> = ({ orderId }) => {
  const [products, setProducts] = useState([]);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [order, setOrder] = useState<Order>();
  const [duplicateOrderId, setDuplicateOrderId] = useState<string>();

  useEffect(() => {
    const fetchData = async () => {
      await productService.getAllProduct().then((res) => setProducts(res.data));
      await orderService
        .getOrderById(duplicateOrderId ? duplicateOrderId : orderId)
        .then((res) => setOrder(res.data));
      await supplierService.getAllSupplier().then((res) => setSuppliers(res.data));
    };
    fetchData();
  }, [duplicateOrderId]);

  const submitSupplier = async (values: OrderInfoEditForm) => {
    try {
      const value: OrderInfoEditForm = { ...values };
      let orderUpdated: { sku: string; quantity: number; originalId?: string | undefined }[] = [];
      let orderDeleted: string[] = [];
      let orderCreated: { sku: string; quantity: number; originalId?: string | undefined }[] = [];

      value.products.map((productOrder) => {
        if (!productOrder.originalId) {
          orderCreated.push(productOrder);
        }
      });
      order?.productOrders.map((productOrder: ProductOrder) => {
        const tmp = value.products.find((element) => element.originalId === productOrder.id);
        if (!tmp) {
          orderDeleted.push(productOrder.id);
        } else {
          const updated = value.products.find(
            (element) =>
              element.originalId === productOrder.id &&
              (element.quantity != productOrder.quantity ||
                element.note != productOrder.note ||
                element.status != productOrder.status)
          );
          if (updated) {
            orderUpdated.push(updated);
          }
        }
      });

      const finalData = {
        expectedAt: value.expectedAt,
        orderReference: value.orderReference,
        paymentStatus: value.paymentStatus,
        quantity: value.quantity,
        status: value.status,
        totalCost: value.totalCost,
        orderUpdated,
        orderDeleted,
        orderCreated
      };
      await orderService.updateOrder(orderId, finalData).then(() => {
        toast.success('Votre commande a été ajoutée avec succès');
      });
    } catch (e) {
      console.error(e);
      toast.error("Une erreur s'est produite, veuillez réessayer plus tard.");
    }
  };

  const generateCsv = (order: Order) => {
    const headers = [
      'ID Commande',
      'Référence Commande',
      'Statut de Paiement',
      'Produit',
      'Quantité Produit',
      'Statut Produit',
      'Note',
      'Nom du Fournisseur',
      'Prestation Fourniseur'
    ];

    const definedService = (productOrder: any, supplierID: string) => {
      const tmp: any = products.find((element: any) => element.sku === productOrder.product.sku);
      const test = tmp?.productSupplier.find(
        (supp: any) => supp.productSupplierName === supplierID
      );
      return test.serviceType ?? '';
    };
    const rows = order.productOrders.map((productOrder) => [
      order.id,
      order.orderReference,
      order.paymentStatus,
      productOrder.productName,
      productOrder.quantity,
      productOrder.status,
      productOrder.note,
      order.supplier.name,
      definedService(productOrder, order.supplier.id)
    ]);

    const csvContent = [
      headers.join(','), // En-tête
      ...rows.map((row) => row.join(',')) // Lignes de données
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Commande.csv');
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  };

  const tmp = order?.productOrders.filter((productOrder: any) => {
    console.log(order);
    return (
      productOrder.productName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      productOrder.product.sku.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const initialValues = useMemo(() => {
    if (order) {
      return EditOrderInitialValueUtils(order, tmp);
    }
  }, [order, searchTerm, tmp]);

  const duplicateOrder = async () => {
    const newOrder = {
      ...initialValues,
      orderReference: initialValues?.orderReference.concat(' copy')
    };
    const cleanData = {
      ...newOrder,
      // eslint-disable-next-line no-unused-vars
      products: newOrder.products.map(({ originalId, ...rest }: any) => rest) // Supprimer originalId de chaque produit
    };

    await orderService.duplicateOrder(cleanData).then((res) => {
      if (res.status === 201) {
        setDuplicateOrderId(res.data.id);
        toast.success('Votre commande a été dupliquée avec succès');
      }
    });
  };

  return (
    <div className="flex flex-col gap-9">
      <div className="rounded-sm shadow-default">
        {order && initialValues && (
          <Formik
            initialValues={initialValues}
            validationSchema={AddOrderFormSchema}
            enableReinitialize={true}
            onSubmit={submitSupplier}>
            {({ values }) => (
              <Form className="p-6.5">
                {order && (
                  <div className="w-full flex pb-2 gap-x-3">
                    <button
                      id="export"
                      type="button"
                      onClick={() => generateCsv(order)}
                      className="p-2 bg-gray text-black rounded flex gap-x-3 items-center w-1/4">
                      <DownloadIcon />
                      Exporter la commande
                    </button>
                    <div className="w-3/4">
                      <div className="relative">
                        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                          <SearchIcon />
                        </div>
                        <input
                          type="text"
                          id="like"
                          onChange={(e) => setSearchTerm(e.target.value)}
                          className="block w-full p-4 ps-10 text-sm text-gray-900 border-stroke border rounded-lg bg-gray-50"
                          placeholder="Sku, Nom du produit"
                        />
                        <div className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 font-medium rounded-lg text-sm px-4 py-2 ">
                          Recherche
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row gap-x-2">
                      <button
                        id=""
                        type="submit"
                        className="flex w-full justify-center rounded bg-primary p-2 items-center font-medium text-gray hover:bg-opacity-90 ">
                        Modifer
                      </button>
                      <button
                        type="button"
                        id="duplicate"
                        onClick={duplicateOrder}
                        className="flex w-full justify-center items-center rounded hover:bg-primary p-2 font-medium bg-white outline text-black hover:text-white">
                        Dupliquer
                      </button>
                    </div>
                  </div>
                )}

                <div className="mb-4.5 flex flex-row gap-6">
                  <div className="w-1/3">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Référence commande
                    </label>
                    <Field
                      name="orderReference"
                      type="text"
                      placeholder="Référence commande"
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 h-12 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    />
                    <ErrorMessage
                      name="orderReference"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                  <div className="w-1/3">
                    <label className="mb-2.5 block text-black">Statut</label>
                    <Field
                      name="status"
                      as="select"
                      className="w-full rounded border-[1.5px] border-stroke h-12 bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary">
                      <option value="IN_PROGRESS">En prod</option>
                      <option value="DONE">Reçu</option>
                      <option value="PARTIAL">Partiellement Reçu</option>
                    </Field>
                    <ErrorMessage name="status" component="div" className="text-red-500 text-sm" />
                  </div>
                  <div className="w-1/3">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Livraison estimée
                    </label>
                    <Field
                      name="expectedAt"
                      type="date"
                      min="2000-01-01"
                      max="2040-12-31"
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent h-12 py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    />
                    <ErrorMessage
                      name="expectedAt"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                </div>

                <div className="flex gap-6 flex-row">
                  <div className="w-full">
                    <label className="mb-2.5 block text-black">Founisseur</label>
                    <Field
                      name="supplierId"
                      as="select"
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent h-12 py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary">
                      <option value="" label="Select founisseur" />
                      {suppliers.map((supplier: Supplier, idx) => (
                        <option key={idx} value={supplier.id}>
                          {supplier.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="products.supplierId"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                  <GlobalQuantity values={values} />
                  <GlobalCost values={values} />
                  <div className="mb-4.5 w-full">
                    <label className="mb-2.5 block text-black">Paiement</label>
                    <Field
                      name="paymentStatus"
                      as="select"
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent h-12 py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary">
                      <option value="IN_PROGRESS">Acompte</option>
                      <option value="DONE">Payé</option>
                      <option value="DECLINED">Non payé</option>
                    </Field>
                    <ErrorMessage
                      name="paymentStatus"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                </div>

                <FieldArray
                  name="products"
                  render={(arrayHelpers) => (
                    <>
                      <div className="flex w-full justify-between border-b mb-3 border-stroke">
                        <span className="flex p-2">Ajouter un produit à la commande</span>
                        <div className="flex gap-x-2">
                          <label
                            htmlFor="myInputFile"
                            className="mb-4 p-2 bg-gray text-black rounded flex gap-x-3 items-center">
                            <DownloadIcon />
                            Import product
                            <input
                              type="file"
                              id="myInputFile"
                              accept=".csv"
                              style={{ display: 'none' }}
                              onChange={(event) => {
                                const files = event?.target?.files;
                                if (files) {
                                  Papa.parse(files[0], {
                                    header: true,
                                    skipEmptyLines: true,
                                    complete: function (results) {
                                      results.data.forEach((prod: any) => {
                                        arrayHelpers.push({
                                          sku: prod.sku,
                                          quantity: prod.quantity
                                        });
                                      });
                                    }
                                  });
                                }
                              }}
                            />
                          </label>
                          <button
                            type="button"
                            className="mb-4 p-2 bg-primary text-white rounded"
                            onClick={() => arrayHelpers.push({ sku: '', quantity: '' })}>
                            Add Product
                          </button>
                        </div>
                      </div>

                      {values.products.map((product: any, index: number) => (
                        <div
                          key={index}
                          className="flex flex-row gap-x-3 mb-4.5 items-center h-full">
                          <div className="w-full">
                            <label className="mb-2.5 block text-black">Sku</label>
                            <Field
                              name={`products.${index}.sku`}
                              as="select"
                              className="w-full rounded border-[1.5px] border-stroke bg-transparent h-12 py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary">
                              <option value="" label="Select SKU" />
                              {products.map((product: any, idx) => (
                                <option key={idx} value={product.sku}>
                                  {product.sku} - {product.productName}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name={`products.${index}.sku`}
                              component="div"
                              className="text-red-500 text-sm"
                            />
                          </div>
                          <div className="w-full">
                            <label className="mb-2.5 block text-black">Quantité</label>
                            <Field
                              name={`products.${index}.quantity`}
                              type="number"
                              placeholder="Quantité"
                              className="w-full rounded border-[1.5px] border-stroke bg-transparent h-12 py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                            />
                            <ErrorMessage
                              name={`products.${index}.quantity`}
                              component="div"
                              className="text-red-500 text-sm"
                            />
                          </div>
                          <div className="w-full">
                            <label className="mb-2.5 block text-black">Statut</label>
                            <Field
                              name={`products.${index}.status`}
                              as="select"
                              className="w-full rounded border-[1.5px] border-stroke bg-transparent h-12 py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary">
                              <option value="PARTIALLY_DONE">Partiellement reçu</option>
                              <option value="IN_PROGRESS">En production</option>
                              <option value="DONE">Reçu</option>
                            </Field>
                            <ErrorMessage
                              name={`products.${index}.status`}
                              component="div"
                              className="text-red-500 text-sm"
                            />
                          </div>
                          <div className="w-full">
                            <label className="mb-2.5 block text-black">Note</label>
                            <Field
                              name={`products.${index}.note`}
                              type="text"
                              placeholder="Note"
                              className="w-full rounded border-[1.5px] border-stroke bg-transparent h-12 py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                            />
                            <ErrorMessage
                              name={`products.${index}.note`}
                              component="div"
                              className="text-red-500 text-sm"
                            />
                          </div>
                          <ProductsOrderCost
                            values={values}
                            products={products}
                            index={index}
                            productOrder={values.products[index]}
                          />
                          <div className="flex h-full mt-10">
                            <button
                              type="button"
                              className="text-red-500 hover:text-red-700"
                              onClick={() => arrayHelpers.remove(index)}>
                              <TrashIcon />
                            </button>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                />
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default ShowMoreOrderInfo;
