import React, { useState } from 'react';

import DefaultLayout from '../../components/layout/DefaultLayout';

import { ArrowLeftIcon } from '../../Icon';
import AddCostForm from './components/AddCostForm';
import CostTable from './components/CostTable';
import ShowMoreCostInfo from './components/ShowMoreCostInfo';
const CostPage: React.FC = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [sidePageOpen, setSidePageOpen] = useState<boolean>(false);
  const [costDataId, setCostDataId] = useState<string>('');

  const closeSide = () => {
    setSidePageOpen(false);
  };
  const displayMore = (id: string) => {
    setSidePageOpen(!sidePageOpen);
    setCostDataId(id);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  return (
    <DefaultLayout>
      <div className="w-full flex justify-between mb-2">
        <span className="p-2 text-lg">{!sidePageOpen ? 'Prix' : 'Détails du prix'}</span>
        {!sidePageOpen && !modalOpen && (
          <button
            className="flex justify-center items-center bg-gray p-2 rounded hover:bg-white hover:border"
            onClick={() => setModalOpen(true)}>
            Ajouter un prix
          </button>
        )}
        {modalOpen && (
          <button
            className="flex justify-center items-center bg-gray p-2 rounded hover:bg-white hover:border"
            onClick={() => setModalOpen(false)}>
            <ArrowLeftIcon />
          </button>
        )}
      </div>
      {!modalOpen && !sidePageOpen && <CostTable showMore={displayMore} />}
      {modalOpen && <AddCostForm onClose={closeModal} />}
      {sidePageOpen && costDataId && <ShowMoreCostInfo onClose={closeSide} costId={costDataId} />}
    </DefaultLayout>
  );
};

export default CostPage;
