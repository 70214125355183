import axiosInstance from '../axiosConfig';
const base_url = process.env.REACT_APP_DEVELOP_API;

const productService = {
  async getAllProduct() {
    return axiosInstance.get(`${base_url}/products/`);
  },

  async createProduct(data: any) {
    return axiosInstance.post(`${base_url}/products/create`, data);
  },

  async updateProduct(id: string, data: any) {
    return axiosInstance.patch(`${base_url}/products/update/${id}`, data);
  },
  async getProductById(id: string) {
    return axiosInstance.get(`${base_url}/products/${id}`);
  },
  async deleteProductById(id: string) {
    return axiosInstance.delete(`${base_url}/products/delete/${id}`);
  }
};
export default productService;
