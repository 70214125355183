import * as Yup from 'yup';

export const AddProductFormSchema = Yup.object({
  sku: Yup.string().required('Le sku du produit est obligatoire'),
  productName: Yup.string().required('Le nom du produit est obligatoire'),
  weight: Yup.number().typeError('Le poids doit être un nombre'),
  type: Yup.string().required('Le type est obligatoire'),
  stoneType: Yup.string(),
  status: Yup.string().required('Le statut est obligatoire'),
  stock: Yup.number().typeError('Le stock doit être un nombre'),
  lunchedAt: Yup.date(),
  color: Yup.string(),
  retailPrice: Yup.number()
});
