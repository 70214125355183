import React, { useEffect } from 'react';
import { Supplier } from '../../../@types/Supplier.type';
import { Field, ErrorMessage, FieldArray, useFormikContext } from 'formik';

import { productServiceTypeList } from '../utils/ProductTypeList';
import { TrashIcon } from '../../../Icon';

interface ProductSupplierProps {
  values: any;
  suppliers: Supplier[];
}

const ProductSupplierForm: React.FC<ProductSupplierProps> = ({ values, suppliers }) => {
  const { setFieldValue } = useFormikContext();

  const calculatePrice = (supplierId: string, serviceType: string) => {
    const supplierSelected = suppliers.find((suply) => suply.id === supplierId);
    if (supplierId && serviceType && supplierSelected) {
      const defaultprice = supplierSelected.costs?.find((cost) => cost.serviceType === serviceType);
      console.log(defaultprice);
      let price = 0;
      if (defaultprice) {
        switch (defaultprice.unit) {
          case 'mm2': {
            price = values.height * values.size * defaultprice.price;
            break;
          }
          case 'unit': {
            price = defaultprice.price;
            break;
          }
          case 'gr': {
            price = values.weight * defaultprice.price;
            break;
          }
          default: {
            price = 0;
            break;
          }
        }
      }
      return price;
    }
    return 0;
  };

  useEffect(() => {
    values.suppliers.forEach(
      (supplier: { productSupplierName: any; serviceType: any }, index: any) => {
        const { productSupplierName, serviceType } = supplier;
        const newPrice = calculatePrice(productSupplierName, serviceType);
        setFieldValue(`suppliers.${index}.cost`, newPrice);
      }
    );
  }, [values, setFieldValue]);

  return (
    <FieldArray
      name="suppliers"
      render={(arrayHelpers) => (
        <>
          <div className="flex w-full justify-between border-b mb-3 border-stroke">
            <span className="flex p-2">Ajouter un fournisseur</span>
            <div className="flex gap-x-2">
              <button
                type="button"
                className="mb-4 p-2 bg-primary text-white rounded"
                onClick={() =>
                  arrayHelpers.push({
                    productSupplierName: '',
                    cost: 0
                  })
                }>
                Add Suppliers
              </button>
            </div>
          </div>

          {values.suppliers.map((supplier: any, index: number) => (
            <div key={index} className="flex flex-row gap-x-3 mb-4.5 items-center h-full">
              <div className="w-full">
                <label className="mb-2.5 block text-black">Fournisseur</label>
                <Field
                  name={`suppliers.${index}.productSupplierName`}
                  as="select"
                  className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary">
                  <option value="" label="Select Fournisseur" />
                  {suppliers.map((sup: any, idx) => (
                    <option key={idx} value={sup?.id}>
                      {sup?.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name={`suppliers.${index}.productSupplierName`}
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
              <div className="w-full">
                <label className="mb-2.5 block text-black">Prestation</label>
                <Field
                  name={`suppliers.${index}.serviceType`}
                  as="select"
                  className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary">
                  <option className="w-full h-full" value="defaultValue">
                    Selectionner un Type de prestation
                  </option>
                  {productServiceTypeList.map((serviceType, index) => (
                    <option key={index} value={serviceType?.value}>
                      {serviceType?.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name={`suppliers.${index}.serviceType`}
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
              <div className="w-full">
                <label className="mb-2.5 block text-black">Prix</label>
                <Field
                  name={`suppliers.${index}.cost`}
                  type="text"
                  disabled={true}
                  className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"></Field>
                <ErrorMessage
                  name={`suppliers.${index}.cost`}
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
              <div className="w-full">
                <label className="mb-2.5 block text-black">Statut</label>
                <Field
                  name={`suppliers.${index}.status`}
                  as="select"
                  className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary">
                  <option value="" label="Select status" />
                  <option value="enabled">ACTIVE</option>
                  <option value="disabled">INACTIVE</option>
                </Field>
                <ErrorMessage
                  name={`suppliers.${index}.status`}
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
              <div className="flex h-full mt-10">
                <button
                  type="button"
                  className="text-red-500 hover:text-red-700"
                  onClick={() => arrayHelpers.remove(index)}>
                  <TrashIcon />
                </button>
              </div>
            </div>
          ))}
        </>
      )}
    />
  );
};

export default ProductSupplierForm;
