import axios from 'axios';
import axiosInstance from '../axiosConfig';
const base_url = process.env.REACT_APP_DEVELOP_API;

const userService = {
  async login(data: { email: string; password: string }) {
    return axios.post(`${base_url}/auth`, data);
  },
  async getUserId() {
    return axiosInstance.get(`${base_url}/auth/profile`);
  },
  async getUser(userId: string) {
    return axiosInstance.get(`${base_url}/auth/user/${userId}`);
  },

  async deleteUser(userId: string) {
    return axiosInstance.delete(`${base_url}/users/delete/${userId}`);
  },

  async getUsers() {
    return axiosInstance.get(`${base_url}/users/all`);
  },

  async register(data: { status: string; email: string; password: string }) {
    return axios.post(`${base_url}/auth/register`, data);
  }
};
export default userService;
