import React, { useEffect, useState } from 'react';
import DefaultLayout from '../../components/layout/DefaultLayout';
import stockService from '../../services/stock/Stock.service';
import { SearchIcon } from '../../Icon';
import LoadingSpinner from '../../components/LoadingSpinner';
const StockPage: React.FC = () => {
  const [products, setProducts] = useState<any[]>([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = async () => {
    setIsLoading(true);
    await stockService.getAllStock().then((res) => {
      setProducts(
        res.data.data.sort((a: any, b: any) =>
          a.sku.localeCompare(b.sku, undefined, { numeric: true })
        )
      );
      setIsLoading(false);
    });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const filteredProducts = products.filter(
    (product) =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.sku.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <DefaultLayout>
      <span className="p-2 text-lg">Stocks</span>
      <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark sm:px-7.5 xl:pb-1 mt-10">
        <div className="w-full mx-auto mb-3.5">
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <SearchIcon />
            </div>
            <input
              type="search"
              id="default-search"
              onChange={(e) => setSearchTerm(e.target.value)}
              className="block w-full p-4 ps-10 text-sm text-gray-900 border-stroke border rounded-lg bg-gray-50"
              placeholder="Sku, nom"
              required
            />
            <button
              type="submit"
              className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 font-medium rounded-lg text-sm px-4 py-2">
              Recherche
            </button>
          </div>
        </div>
        {!isLoading ? (
          <div>
            <div className="max-w-full overflow-x-auto">
              <table className="w-full table-fixed">
                <thead>
                  <tr className="bg-gray-2 text-left dark:bg-meta-4">
                    <th className="min-w-[120px] py-4 px-4 font-medium text-black text-center">
                      Sku
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-medium text-black text-center">
                      Nom
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-medium text-black text-center">
                      Usine
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-medium text-black text-center">
                      Dorure
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-medium text-black text-center">
                      Assamblage / Ensachetage
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-medium text-black text-center">
                      Stock
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {products &&
                    filteredProducts.map((product: any, key: number) => (
                      <tr key={key} className="">
                        <td className="border-b border-[#eee] py-5 px-4">
                          <h5 className="font-medium text-black dark:text-white text-center">
                            {product.sku}
                          </h5>
                        </td>
                        <td className="border-b border-[#eee] py-5 px-4">
                          <h5 className="font-medium text-black dark:text-white text-center">
                            {product.name}
                          </h5>
                        </td>
                        <td className="border-b border-[#eee] py-5 px-4">
                          <p className="text-sm text-center">{product.prodQuantity}</p>
                        </td>
                        <td className="border-b border-[#eee] py-5 px-4">
                          <p className="text-sm text-center">{product.gildingQuantity}</p>
                        </td>
                        <td className="border-b border-[#eee] py-5 px-4">
                          <p className="text-sm text-center">{product.assemblyQuantity}</p>
                        </td>
                        <td className="border-b border-[#eee] py-5 px-4">
                          <p className="text-sm text-center">{product.global}</p>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </DefaultLayout>
  );
};

export default StockPage;
