import React, { useEffect, useState } from 'react';

import { formatDate } from '../../../utils/formatDate';
import { toast } from 'react-toastify';
import { EyeIcon, SearchIcon, TrashIcon } from '../../../Icon';
import orderService from '../../../services/order/Order.service';
import { Order } from '../../../@types/Order.type';
import Modal from '../../../components/Moal';

interface OrderTableProps {
  // eslint-disable-next-line no-unused-vars
  showMore: (id: string, orderRef: string) => void;
}
const OrderTable: React.FC<OrderTableProps> = ({ showMore }) => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [selectedId, setSelectedId] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchData = async () => {
    await orderService.getAllOrder().then((res) => {
      setOrders(res.data);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleShowMore = (id: string, orderRef: string) => {
    showMore(id, orderRef);
  };

  const closeModal = () => setModalOpen(false);

  const modal = (id: string) => {
    setModalOpen(true);
    setSelectedId(id);
  };

  const deleteProduct = async (id: string) => {
    try {
      await orderService.deleteOrderById(id).then(() => {
        toast.success('Commande supprimer correctement');
        closeModal();
        fetchData();
      });
    } catch (e) {
      toast.error('Une erreur est survenu ');
      console.error(e);
    }
  };

  const filteredOrders = orders.filter((order) => {
    return (
      order.orderReference.toLowerCase().includes(searchTerm.toLowerCase()) ||
      order.productOrders.some((productOrder) =>
        productOrder.productName.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  });

  return (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default sm:px-7.5 xl:pb-1">
      <div className="w-full mx-auto mb-3.5">
        <div className="relative">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <SearchIcon />
          </div>
          <input
            type="search"
            id="default-search"
            onChange={(e) => setSearchTerm(e.target.value)}
            className="block w-full p-4 ps-10 text-sm text-gray-900 border-stroke border rounded-lg bg-gray-50"
            placeholder="Réference, Sku"
            required
          />
          <div className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 font-medium rounded-lg text-sm px-4 py-2">
            Recherche
          </div>
        </div>
      </div>
      <div className="max-w-full overflow-x-auto">
        <table className="w-full table-fixed">
          <thead>
            <tr className="bg-gray-2 text-left dark:bg-meta-4">
              <th className="min-w-[120px] py-4 px-4 font-medium text-black text-center">
                Référence
              </th>
              <th className="min-w-[120px] py-4 px-4 font-medium text-black text-center">
                Fournisseur
              </th>
              <th className="min-w-[120px] py-4 px-4 font-medium text-black text-center">Statut</th>
              <th className="min-w-[120px] py-4 px-4 font-medium text-black text-center">
                Commandé le
              </th>
              <th className="min-w-[120px] py-4 px-4 font-medium text-black text-center">
                Livraison estimée
              </th>
              <th className="min-w-[120px] py-4 px-4 font-medium text-black text-center">
                Quantité
              </th>
              <th className="py-4 px-4 font-medium text-black text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {orders &&
              filteredOrders
                .sort(
                  (a: any, b: any) =>
                    new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
                )
                .map((order: any, key: number) => (
                  <tr key={key} className="">
                    <td className="border-b border-[#eee] py-5 px-4">
                      <h5 className="font-medium text-black dark:text-white text-center">
                        {order.orderReference}
                      </h5>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4">
                      <p className="text-sm text-center">{order.supplier?.name}</p>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4">
                      <p
                        className={`rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium text-center ${
                          order.status === 'DONE'
                            ? 'bg-success text-success'
                            : order.status === 'PARTIAL'
                              ? 'bg-danger text-danger'
                              : 'bg-warning text-warning'
                        }`}>
                        {order.status === 'IN_PROGRESS'
                          ? 'En prod'
                          : order.status === 'DONE'
                            ? ' Reçu'
                            : 'Partiellement Reçu'}
                      </p>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                      <p className="text-black dark:text-white text-center">
                        {formatDate(order.createdAt)}
                      </p>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                      <h5 className="font-medium text-black dark:text-white text-center">
                        {formatDate(order?.expectedAt)}
                      </h5>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                      <h5 className="font-medium text-black dark:text-white text-center">
                        {order?.quantity}
                      </h5>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4">
                      <div className="flex items-center space-x-3.5 justify-center">
                        <button
                          className="hover:text-primary"
                          onClick={() => handleShowMore(order.id, order.orderReference)}>
                          <EyeIcon />
                        </button>
                        <button className="hover:text-primary" onClick={() => modal(order.id)}>
                          <TrashIcon />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      <Modal
        isOpen={modalOpen}
        onClose={closeModal}
        title="Etes vous sur de vouloir supppimer la commande">
        <div className="flex justify-end gap-x-3 w-full">
          <button
            className="flex justify-center items-center bg-gray p-2 rounded hover:bg-white hover:border"
            onClick={() => closeModal()}>
            Annuler
          </button>
          <button
            className="flex justify-center items-center bg-gray p-2 rounded hover:bg-white hover:border"
            onClick={() => deleteProduct(selectedId ?? '')}>
            Supprimer
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default OrderTable;
