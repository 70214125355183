import { Product } from '../../../@types/Product.type';

export const AddProductInitialValueUtils = {
  sku: '',
  productName: '',
  status: 'ONLINE',
  collectionName: '',
  type: '',
  color: '',
  retailPrice: '',
  note: '',
  weight: 0,
  size: 0,
  height: 0,
  depth: 0,
  stock: 0,
  lunchedAt: '',
  stoneType: '',
  suppliers: []
};

export const EditProductInitialValueUtils = (product: Product) => {
  return {
    sku: product?.sku || '',
    productName: product?.productName || '',
    weight: product?.weight || 0,
    height: product?.height || 0,
    depth: product?.depth || 0,
    type: product?.type || '',
    note: product?.note || '',
    stoneType: product?.stoneType || '',
    status: product?.status || '',
    stock: product?.stock || '',
    size: (product?.size as number) || 0,
    color: product?.color || '',
    retailPrice: product?.retailPrice.toString() || '',
    lunchedAt: product?.lunchedAt?.split('T')[0] || '',
    collectionName: product?.collectionName || '',
    suppliers:
      product?.productSupplier?.map((productSupplier: any) => {
        return {
          productSupplierName: productSupplier?.productSupplierName,
          status: productSupplier?.status,
          serviceType: productSupplier?.serviceType,
          originalId: productSupplier?.id,
          cost: productSupplier?.cost
        };
      }) || []
  };
};
