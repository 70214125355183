import React, { useState } from 'react';
import DefaultLayout from '../../components/layout/DefaultLayout';
import SupplierTable from './components/SupplierTable';
import AddSupplierModal from './components/AddSupplierModal';
import ShowMoreSupplierInfo from './components/ShowMoreSupplierInfo';

const SupplierPage: React.FC = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [sidePageOpen, setSidePageOpen] = useState<boolean>(false);
  const [supplierDataId, setSupplierDataId] = useState<string>('');

  const closeModal = () => {
    setModalOpen(false);
  };

  const closeSide = () => {
    setSidePageOpen(false);
  };
  const displayMore = (id: string) => {
    setSidePageOpen(!sidePageOpen);
    setSupplierDataId(id);
  };

  return (
    <DefaultLayout>
      <div className="w-full flex justify-between mb-2">
        <span className="p-2 text-lg">Fournisseur</span>
        <button
          className="flex justify-center items-center bg-gray p-2 rounded"
          onClick={() => setModalOpen(true)}>
          Ajouter un fournisseur
        </button>
      </div>
      {!modalOpen && <SupplierTable showMore={displayMore} />}
      {modalOpen && <AddSupplierModal onClose={closeModal} />}
      {sidePageOpen && supplierDataId && (
        <ShowMoreSupplierInfo onClose={closeSide} supplierId={supplierDataId} />
      )}
    </DefaultLayout>
  );
};

export default SupplierPage;
