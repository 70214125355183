export const productTypeList = [
  {
    value: 'necklace',
    name: 'Necklace'
  },
  {
    value: 'earrings',
    name: 'Earrings'
  },
  {
    value: 'ring',
    name: 'Ring'
  },
  {
    value: 'charm',
    name: 'Charm'
  },
  {
    value: 'bracelet',
    name: 'Bracelet'
  },
  {
    value: 'accessories',
    name: 'Accessories'
  },
  {
    value: 'goodies',
    name: 'Goodies'
  },
  {
    value: 'packaging',
    name: 'Packaging'
  },
  {
    value: 'other',
    name: 'Other'
  }
];

export const productServiceTypeList = [
  {
    value: 'goldPlating',
    name: 'Plaquage or'
  },
  {
    value: 'silverPlating',
    name: 'Plaquage argent'
  },
  {
    value: 'brass',
    name: 'Laiton'
  },
  {
    value: 'goldFilled',
    name: 'Doré à l’or fin'
  },
  {
    value: 'packaging',
    name: 'Packaging'
  },
  {
    value: 'sentPouch',
    name: 'Pochon actuel'
  },
  {
    value: 'sentFlyer',
    name: 'Flyer actuel'
  },
  {
    value: 'sentPackaging',
    name: 'Boîte tiroir actuelle'
  },
  {
    value: 'shipping',
    name: 'Shipping'
  },
  {
    value: 'other',
    name: 'Other'
  }
];
