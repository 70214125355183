import React, { useState } from 'react';
import DefaultLayout from '../../components/layout/DefaultLayout';
import OrderTable from './components/OrderTable';
import AddOrderForm from './components/AddOrderForm';
import ShowMoreOrderInfo from './components/ShowMoreOrderInfo';
import { ArrowLeftIcon } from '../../Icon';
const OrderPage: React.FC = () => {
  const [sidePageOpen, setSidePageOpen] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [orderDataId, setOrderDataId] = useState<string>('');
  const [orderRef, setOrderRefId] = useState<string | null>('');

  const handelModal = () => {
    setModalOpen(!modalOpen);
  };

  const displayMore = (id: string, orderRef: string) => {
    setSidePageOpen(!sidePageOpen);
    setOrderDataId(id);
    setOrderRefId(orderRef);
  };
  return (
    <DefaultLayout>
      <div className="w-full flex justify-between mb-2">
        <span className="p-2 text-lg">{!orderDataId ? 'Commandes' : `Commande ${orderRef}`}</span>
        {!sidePageOpen && (
          <button
            className="flex justify-center items-center bg-gray p-2 rounded"
            onClick={() => {
              setOrderDataId('');
              setOrderRefId('');
              handelModal();
            }}>
            {!modalOpen ? 'Ajouter une commande' : <ArrowLeftIcon />}
          </button>
        )}
        {sidePageOpen && (
          <button
            className="flex justify-center items-center bg-gray p-2 rounded"
            onClick={() => setSidePageOpen(!sidePageOpen)}>
            <ArrowLeftIcon />
          </button>
        )}
      </div>
      {!sidePageOpen && !modalOpen && <OrderTable showMore={displayMore} />}
      {modalOpen && <AddOrderForm />}
      {sidePageOpen && orderDataId && (
        <ShowMoreOrderInfo onClose={handelModal} orderId={orderDataId} />
      )}
    </DefaultLayout>
  );
};

export default OrderPage;
