import React, { useState } from 'react';
import DefaultLayout from '../../components/layout/DefaultLayout';
import UserTable from './components/UserTable';
import Modal from '../../components/Moal';
import { Field, Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { EyeIcon, EyeSlashIcon } from '../../Icon';
import userService from '../../services/user/User.service';

const UserPage: React.FC = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  const register = async (values: any) => {
    try {
      await userService.register(values).then((res) => {
        if (res.status === 201) {
          toast.success('Utilisateur créer');
          closeModal();
        }
      });
    } catch (e) {
      toast.error('Email ou mots de passe invalide');
    }
  };

  return (
    <DefaultLayout>
      <div className="w-full flex justify-between mb-2">
        <span className="p-2 text-lg">Utilisateurs</span>
        <button
          className="flex justify-center items-center bg-gray p-2 rounded"
          onClick={() => setModalOpen(true)}>
          Ajouter un utilisateur
        </button>
      </div>
      <Modal isOpen={modalOpen} onClose={closeModal} title="Ajouter un utilisateur">
        <Formik
          onSubmit={register}
          initialValues={{
            email: '',
            password: '',
            status: ''
          }}>
          <Form className="h-full w-full flex-col space-y-4">
            <div className="flex flex-col w-full">
              <label htmlFor="email" className="mb-1 font-lato text-xs opacity-50">
                Email
              </label>
              <Field
                id="email"
                name="email"
                placeholder="jane@exemple.com"
                type="email"
                className="relative w-full px-3 py-2 border border-gray-300 placeholder-gray-500
                    text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500  sm:text-sm rounded"
              />
            </div>
            <div className="flex flex-col w-full">
              <label htmlFor="status" className="mb-1 font-lato text-xs opacity-50">
                Status
              </label>
              <Field
                id="status"
                as="select"
                name="status"
                className="relative w-full px-3 py-2 border border-gray-300 placeholder-gray-500
                    text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded">
                <option className="w-full h-full" value="DEFAULT">
                  Selectionez un statut
                </option>
                <option className="w-full h-full" value="OPS">
                  OPS
                </option>
                <option className="w-full h-full" value="ADMIN">
                  ADMIN
                </option>
              </Field>
            </div>
            <div className="flex flex-col w-full">
              <label htmlFor="password" className="mb-1 font-lato text-xs opacity-50">
                Mot de passe
              </label>
              <div className="flex w-full relative">
                <Field
                  id="password"
                  name="password"
                  placeholder="Mot de passe"
                  type={showPassword ? 'text' : 'password'}
                  className="w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900
                      focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded"
                />
                <div
                  className="absolute top-[50%] transform -translate-y-1/2  right-2 text-gray-400 cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <EyeIcon /> : <EyeSlashIcon />}
                </div>
              </div>
            </div>
            <div className="flex gap-x-3">
              <button
                className="h-[49px] group relative w-full items-center flex justify-center py-2 px-4 border
                    border-transparent text-sm font-medium hover:text-white hover:bg-black bg-white text-black transition-all ease-in delay-100 outline rounded"
                onClick={() => closeModal()}>
                Annuler
              </button>
              <button
                type="submit"
                className="h-[49px] group relative w-full items-center flex justify-center py-2 px-4 border
                    border-transparent text-sm font-medium text-white bg-black hover:bg-white hover:text-black transition-all ease-in delay-100 hover:outline rounded">
                Créer
              </button>
            </div>
          </Form>
        </Formik>
      </Modal>
      <UserTable />
    </DefaultLayout>
  );
};

export default UserPage;
