import React, { useEffect, useMemo, useState } from 'react';
import { CloseIcon } from '../../../Icon';
import { toast } from 'react-toastify';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import classNames from 'classnames';
import supplierService from '../../../services/supplier/Supplier.service';
import { Supplier } from '../../../@types/Supplier.type';
import { EditCostInitialValueUtils } from '../utils/CostInitialValue.utils';
import costService from '../../../services/cost/Cost.service';
import { Cost } from '../../../@types/Cost.type';
import { EditCostFormSchema } from '../@types/EdidCostForm.schema';
import { productServiceTypeList } from '../../Product/utils/ProductTypeList';

interface CostInfoProps {
  onClose: () => void;
  costId: string;
}

const ShowMoreCostInfo: React.FC<CostInfoProps> = ({ onClose, costId }) => {
  const [cost, setCost] = useState<Cost>();
  const [suppliers, setSuppliers] = useState([]);

  const fetchData = async () => {
    try {
      await supplierService.getAllSupplier().then((res) => setSuppliers(res.data));
      await costService.getCostById(costId).then((res) => {
        setCost(res.data);
      });
    } catch (e) {
      toast.error('Une erreur est survenu');
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const initialValues = useMemo(() => {
    if (cost && suppliers) {
      return EditCostInitialValueUtils(cost);
    }
  }, [cost, suppliers]);

  const handleSubmit = async (values: any) => {
    try {
      await costService.updateCost(costId, values).then((res) => console.log(res));
      toast.success('Prix mis à jour avec succès');
      onClose();
    } catch (e) {
      toast.error('Une erreur est survenue lors de la mise à jour');
    }
  };

  return (
    <div className="w-full h-full ">
      <button className="absolute top-5 right-2 text-xl" onClick={onClose}>
        <CloseIcon />
      </button>
      {cost && initialValues && (
        <Formik
          initialValues={initialValues}
          validationSchema={EditCostFormSchema}
          enableReinitialize={true}
          onSubmit={handleSubmit}>
          {({ dirty }) => (
            <Form className="p-6.5">
              <div className="mb-4.5 flex flex-row gap-6">
                <div className="w-full">
                  <label className="mb-2.5 block text-black text-sm">Price</label>
                  <Field
                    name="price"
                    type="text"
                    placeholder="price"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  />
                  <ErrorMessage name="price" component="div" className="text-red-500 text-sm" />
                </div>

                <div className="w-full">
                  <label className="mb-2.5 block text-black text-sm">Unité</label>
                  <Field
                    name="unit"
                    as="select"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary">
                    <option className="w-full h-full" value="gr">
                      gr
                    </option>
                    <option className="w-full h-full" value="mm2">
                      mm2 (surface)
                    </option>
                    <option className="w-full h-full" value="unit">
                      unit
                    </option>
                  </Field>
                  <ErrorMessage name="unit" component="div" className="text-red-500 text-sm" />
                </div>
              </div>
              <div className="mb-4.5 flex flex-row gap-6">
                <div className="w-full">
                  <label className="mb-2.5 block text-black text-sm">Type de service</label>
                  <Field
                    name="serviceType"
                    as="select"
                    placeholder="service"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary">
                    <option className="w-full h-full" value="defaultValue">
                      Selectionner un Type de prestation
                    </option>
                    {productServiceTypeList.map((serviceType, index) => (
                      <option key={index} value={serviceType?.value}>
                        {serviceType?.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="serviceType"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>
                <div className="w-full">
                  <label className="mb-2.5 block text-black text-sm">Fournisseur</label>
                  <Field
                    name="supplier"
                    as="select"
                    placeholder=""
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary">
                    {suppliers?.map((supplier: Supplier, index: number) => (
                      <option className="w-full h-full" value={supplier.id} key={index}>
                        {supplier.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="supplier" component="div" className="text-red-500 text-sm" />
                </div>
              </div>
              <button
                type="submit"
                className={classNames(
                  'mt-5 bg-blue-500 text-white py-2 px-4 rounded',
                  !dirty && 'opacity-50 pointer-events-none'
                )}>
                Enregistrer
              </button>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default ShowMoreCostInfo;
