import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { User } from '../pages/@types/user.type';
import { userStore } from '../stores/UserStore.atom';

export const RequireStatus: React.FC<{ status: string }> = ({ status }) => {
  const user = useRecoilValue<User | undefined>(userStore);

  if (user && user?.status !== status) {
    toast.warning(`vous n'avez pas accès`);
    return <Navigate to="/dashboard" replace />;
  }

  return <Outlet />;
};
