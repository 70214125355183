import React, { useEffect, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { CloseIcon } from '../../../Icon';
import supplierService from '../../../services/supplier/Supplier.service';
import supplierTypeService from '../../../services/supplierTypes/SupplierType.service';
import { toast } from 'react-toastify';
import { SupplierType } from '../../../@types/SupplierType.type';
import { SupplierValidationSchema } from '../@types/AddSupplierForm.schema';
import { AddSupplierInitialValueUtils } from '../utils/SupplierInitialValue.utils';

interface ProductModalProps {
  onClose: () => void;
}

const AddSupplierModal: React.FC<ProductModalProps> = ({ onClose }) => {
  const [showMoreContact, setShowMoreContact] = useState<boolean>(false);
  const [supplierTypes, setSupplierTypes] = useState<SupplierType[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      await supplierTypeService.getAllSupplierType().then((res) => setSupplierTypes(res.data));
    };
    fetchData();
  }, []);
  const submitSupplier = async (values: any) => {
    try {
      await supplierService.createSupplier(values);
      toast.success('Votre fournisseur a été ajoutée avec succès');
      onClose();
    } catch (e) {
      toast.error("Une erreur s'est produite veuillez recommencer plus tard.");
      onClose();
    }
  };

  return (
    <div className="fixed z-40 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <div
          className="inline-block align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 border-b border-stroke">
            <div className="flex w-full justify-between">
              <span>Ajouter un fournisseur</span>
              <button onClick={onClose} type="button">
                <CloseIcon />
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-9">
            {/* <!-- Contact Form --> */}
            <div className="rounded-sm shadow-default">
              <Formik
                initialValues={AddSupplierInitialValueUtils}
                validationSchema={SupplierValidationSchema}
                onSubmit={submitSupplier}>
                <Form className="p-6.5">
                  <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                    <div className="w-full xl:w-1/2">
                      <label className="mb-2.5 block text-black dark:text-white">
                        Nom du fournisseur
                      </label>
                      <Field
                        name="name"
                        type="text"
                        placeholder="Nom"
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      />
                      <ErrorMessage name="name" component="div" className="text-red-500 text-sm" />
                    </div>

                    <div className="w-full xl:w-1/2">
                      <label className="mb-2.5 block text-black dark:text-white">Type</label>
                      <Field
                        name="type"
                        as="select"
                        placeholder="Enter your last name"
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary">
                        <option className="w-full h-full" value="Selectionnez un type">
                          Selectionner un type
                        </option>
                        {supplierTypes?.map((supplierType: SupplierType, index: number) => (
                          <option className="w-full h-full" value={supplierType.id} key={index}>
                            {supplierType.type}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage name="type" component="div" className="text-red-500 text-sm" />
                    </div>
                  </div>

                  <div className="mb-4.5">
                    <label className="mb-2.5 block text-black dark:text-white">Adresse</label>
                    <Field
                      name="location"
                      type="text"
                      placeholder="Addresse postal "
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    />
                    <ErrorMessage
                      name="location"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div className="mb-4.5">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Délai de paiement
                    </label>
                    <Field
                      name="paymentTerms"
                      type="text"
                      placeholder="1 à 2 mois"
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    />
                    <ErrorMessage
                      name="paymentTerms"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div className="mb-4.5">
                    <label className="mb-2.5 text-black flex justify-between">
                      Contact 1
                      <span
                        className="flex bg-gray rounded-full rotate-45 hover:-rotate-45 ease-in-out transition-all hover:bg-white hover:border"
                        onClick={() => setShowMoreContact(!showMoreContact)}>
                        <CloseIcon />
                      </span>
                    </label>
                    <Field
                      name="contact1"
                      type="text"
                      placeholder="Select subject"
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    />
                    <ErrorMessage
                      name="contact1"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                  {showMoreContact && (
                    <div>
                      <div className="mb-4.5">
                        <label className="mb-2.5 block text-black dark:text-white">Contact 2</label>
                        <Field
                          name="contact2"
                          type="text"
                          placeholder="Select subject"
                          className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                        />
                        <ErrorMessage
                          name="contact2"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                      <div className="mb-4.5">
                        <label className="mb-2.5 block text-black dark:text-white">Contact 3</label>
                        <Field
                          name="contact3"
                          type="text"
                          placeholder="Select subject"
                          className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                        />
                        <ErrorMessage
                          name="contact3"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                    </div>
                  )}
                  <div className="mb-6">
                    <label className="mb-2.5 block text-black dark:text-white">Note</label>
                    <Field
                      name="note"
                      as="textarea"
                      rows={2}
                      placeholder="Type your message"
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                    />
                    <ErrorMessage name="note" component="div" className="text-red-500 text-sm" />
                  </div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray hover:bg-opacity-90">
                    Ajouter le fournisseur
                  </button>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSupplierModal;
