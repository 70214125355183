import React, { useEffect, useState } from 'react';
import { SearchIcon, TrashIcon } from '../../../Icon';
import Modal from '../../../components/Moal';
import userService from '../../../services/user/User.service';
import { User } from '../../@types/user.type';
import { formatDate } from '../../../utils/formatDate';
import { toast } from 'react-toastify';

const UserTable: React.FC = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState(false);

  const [selectedId, setSelectedId] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const closeModal = () => setModalOpen(false);

  const modal = (id: string) => {
    setModalOpen(true);
    setSelectedId(id);
  };

  const fetchData = async () => {
    await userService.getUsers().then((res) => setUsers(res.data));
  };
  useEffect(() => {
    fetchData();
  }, []);

  const deleteProduct = async (id: string) => {
    try {
      await userService.deleteUser(id).then(() => {
        toast.success('User supprimer correctement');
        fetchData();
        closeModal();
      });
    } catch (e) {
      toast.error('Une erreur est survenu ');
      console.error(e);
    }
  };

  const filteredProducts = users.filter((user) =>
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      <Modal
        isOpen={modalOpen}
        onClose={closeModal}
        title="Etes vous sur de vouloir supppimer le l'utilisateur">
        <div className="flex justify-end gap-x-3 w-full">
          <button
            className="flex justify-center items-center bg-gray p-2 rounded hover:bg-white hover:border"
            onClick={() => closeModal()}>
            Annuler
          </button>
          <button
            className="flex justify-center items-center bg-gray p-2 rounded hover:bg-white hover:border"
            onClick={() => deleteProduct(selectedId ?? '')}>
            Supprimer
          </button>
        </div>
      </Modal>
      <div className="w-full mx-auto mb-3.5">
        <div className="relative">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <SearchIcon />
          </div>
          <input
            type="search"
            id="default-search"
            onChange={(e) => setSearchTerm(e.target.value)}
            className="block w-full p-4 ps-10 text-sm text-gray-900 border-stroke border rounded-lg bg-gray-50"
            placeholder="Email"
            required
          />
          <button
            type="submit"
            className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 font-medium rounded-lg text-sm px-4 py-2">
            Recherche
          </button>
        </div>
      </div>
      <div className="max-w-full overflow-x-auto">
        <table className="w-full table-fixed">
          <thead>
            <tr className="bg-gray-2 text-left">
              <th className="min-w-[120px] py-4 px-4 font-medium text-black xl:pl-11">Email</th>
              <th className="min-w-[120px] py-4 px-4 font-medium text-black text-center">
                Date de création
              </th>
              <th className="min-w-[120px] py-4 px-4 font-medium text-black text-center">Statut</th>
              <th className="py-4 px-4 font-medium text-black text-center ">Action</th>
            </tr>
          </thead>
          <tbody>
            {users &&
              filteredProducts.map((user: User, key: number) => (
                <tr key={key} className="">
                  <td className="border-b border-[#eee] py-5 px-4 items-start">
                    <p className="text-sm text-center">{user.email}</p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4">
                    <p className="text-black dark:text-white text-center">
                      {formatDate(user.createdAt)}
                    </p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 text-center">
                    <p
                      className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium text-center ${
                        user.status === 'ADMIN'
                          ? 'bg-success text-success'
                          : 'bg-danger text-warning'
                      }`}>
                      {user.status}
                    </p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 w-full text-center">
                    <button className="hover:text-primary " onClick={() => modal(user.id)}>
                      <TrashIcon />
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserTable;
