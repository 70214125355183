import React, { useEffect, useMemo, useState } from 'react';
import { CloseIcon } from '../../../Icon';
import supplierService from '../../../services/supplier/Supplier.service';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import classNames from 'classnames';
import { Supplier } from '../../../@types/Supplier.type';
import { EditSupplierValidationSchema } from '../@types/EditSupplierForm.schema';
import { EditSupplierInitialValueUtils } from '../utils/SupplierInitialValue.utils';

interface ShowMoreSupplierInfoProps {
  onClose: () => void;
  supplierId: string;
}

const ShowMoreSupplierInfo: React.FC<ShowMoreSupplierInfoProps> = ({ onClose, supplierId }) => {
  const [supplier, setSupplier] = useState<Supplier>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await supplierService.getSupplierById(supplierId).then((res) => setSupplier(res.data));
      } catch (e) {
        toast.error('Une erreur est survenu');
      }
    };
    fetchData();
  }, []);

  const initialValues = useMemo(() => {
    if (supplier) {
      return EditSupplierInitialValueUtils(supplier);
    }
  }, [supplier]);

  const handleSubmit = async (values: any) => {
    try {
      await supplierService.updateSupplierById(supplierId, values);
      toast.success('Fournisseur mis à jour avec succès');
      onClose();
    } catch (e) {
      toast.error('Une erreur est survenue lors de la mise à jour');
    }
  };

  return (
    <div className="fixed top-0 w-1/3 right-0 h-full bg-white shadow-lg z-50">
      <button className="absolute top-5 right-2 text-xl" onClick={onClose}>
        <CloseIcon />
      </button>
      <h2 className="mt-5 px-4 text-lg">Détails du fournisseur</h2>

      {supplier && initialValues && (
        <Formik
          initialValues={initialValues}
          validationSchema={EditSupplierValidationSchema}
          onSubmit={handleSubmit}>
          {({ dirty }) => (
            <Form className="flex w-full h-full p-4 flex-col gap-y-3 mt-10">
              <div className="flex items-center">
                <span className="bg-gray-300 p-3 w-36 inline-block">ID du fournisseur :</span>
                <span>{supplier.id}</span>
              </div>
              <div className="flex items-center gap-x-3">
                <span className="bg-gray p-3 w-36 inline-block">Nom :</span>
                <Field name="name" className="p-2 border border-stroke rounded" />
                <ErrorMessage name="name" component="div" className="text-red-600" />
              </div>
              <div className="flex items-center gap-x-3">
                <span className="bg-gray p-3 w-36 inline-block">Type :</span>
                <Field
                  name="type.type"
                  disabled
                  className="p-2 border pointer-events-none border-stroke rounded"
                />
                <ErrorMessage name="type.type" component="div" className="text-red-600" />
              </div>
              <div className="flex items-center gap-x-3">
                <span className="bg-gray p-3 w-36 inline-block">Adresse :</span>
                <Field name="location" className="p-2 border border-stroke rounded" />
                <ErrorMessage name="contact1" component="div" className="text-red-600" />
              </div>
              <div className="flex items-center gap-x-3">
                <span className="bg-gray p-3 w-36 inline-block">Contact 1 :</span>
                <Field name="contact1" className="p-2 border border-stroke rounded" />
                <ErrorMessage name="contact1" component="div" className="text-red-600" />
              </div>
              <div className="flex items-center gap-x-3">
                <span className="bg-gray p-3 w-36 inline-block">Contact 2 :</span>
                <Field name="contact2" className="p-2 border border-stroke rounded" />
                <ErrorMessage name="contact2" component="div" className="text-red-600" />
              </div>
              <div className="flex items-center gap-x-3">
                <span className="bg-gray p-3 w-36 inline-block">Contact 3 :</span>
                <Field name="contact3" className="p-2 border border-stroke rounded" />
                <ErrorMessage name="contact3" component="div" className="text-red-600" />
              </div>
              <div className="flex items-center gap-x-3">
                <span className="bg-gray p-3 w-36 inline-block">Note :</span>
                <Field name="note" className="p-2 border border-stroke rounded" />
                <ErrorMessage name="note" component="div" className="text-red-600" />
              </div>
              <div className="flex items-center gap-x-3">
                <span className="bg-gray p-3 w-36 inline-block">Délai de paiement :</span>
                <Field name="paymentTerms" className="p-2 border border-stroke rounded" />
                <ErrorMessage name="paymentTerms" component="div" className="text-red-600" />
              </div>

              <button
                type="submit"
                className={classNames(
                  'mt-5 bg-blue-500 text-white py-2 px-4 rounded',
                  !dirty && 'opacity-50 pointer-events-none'
                )}>
                Enregistrer
              </button>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default ShowMoreSupplierInfo;
