import React from 'react';
import { Supplier } from '../../../@types/Supplier.type';
import { Field, ErrorMessage, FieldArray, useFormikContext } from 'formik';

import { productServiceTypeList } from '../utils/ProductTypeList';
import { LoadingIcon, TrashIcon } from '../../../Icon';

interface ProductSupplierProps {
  values: any;
  suppliers: Supplier[];
  initialValues: any;
}

const EditProductSupplierForm: React.FC<ProductSupplierProps> = ({
  values,
  suppliers,
  initialValues
}) => {
  const { setFieldValue } = useFormikContext();

  const setDefaultPrice = async (index: number, values: any, productSupplier: any) => {
    const supplierSelected: any = suppliers.find(
      (suply: any) => suply.id === productSupplier.productSupplierName
    );
    let price = 1;

    if (productSupplier && values && supplierSelected) {
      const defaultprice = supplierSelected.costs?.find(
        (cost: { serviceType: string }) => cost.serviceType === productSupplier.serviceType
      );
      if (defaultprice) {
        switch (defaultprice.unit) {
          case 'mm2': {
            const tmp = values.height * values.size;
            price = tmp * defaultprice.price;
            break;
          }
          case 'unit': {
            price = values.stock * defaultprice.price;
            break;
          }
          case 'gr': {
            price = values.weight * defaultprice.price;
            break;
          }
          default: {
            price = 0;
            break;
          }
        }
      }
    }
    setFieldValue(`suppliers.${index}.cost`, price ?? 0);
  };

  return (
    <FieldArray
      name="suppliers"
      render={(arrayHelpers) => (
        <>
          <div className="flex w-full justify-between border-b mb-3 border-stroke">
            <span className="flex p-2">Ajouter un fournisseur</span>
            <div className="flex gap-x-2">
              <button
                type="button"
                className="mb-4 p-2 bg-primary text-white rounded"
                onClick={() =>
                  arrayHelpers.push({
                    productSupplierName: '',
                    cost: 0
                  })
                }>
                Add Suppliers
              </button>
            </div>
          </div>

          {values.suppliers.map((supplier: any, index: number) => (
            <div key={index} className="flex flex-row gap-x-3 mb-4.5 items-center h-full">
              <div className="w-full">
                <label className="mb-2.5 block text-black">Fournisseur</label>
                <Field
                  name={`suppliers.${index}.productSupplierName`}
                  as="select"
                  disabled={!!initialValues?.suppliers[index]?.productSupplierName}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent h-12 py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary">
                  <option value="" label="Select Fournisseur" />
                  {suppliers.map((sup: any, idx) => (
                    <option key={idx} value={sup?.id}>
                      {sup?.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name={`suppliers.${index}.productSupplierName`}
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
              <div className="w-full">
                <label className="mb-2.5 block text-black">Prestation</label>
                <Field
                  name={`suppliers.${index}.serviceType`}
                  as="select"
                  disabled={!!initialValues?.suppliers[index]?.serviceType}
                  className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary">
                  <option className="w-full h-full" value="defaultValue">
                    Selectionner un Type de prestation
                  </option>
                  {productServiceTypeList.map((serviceType, index) => (
                    <option key={index} value={serviceType?.value}>
                      {serviceType?.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name={`suppliers.${index}.productSupplierName`}
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
              <div className="w-full">
                <label className="mb-2.5 block text-black">Prix</label>
                <Field
                  name={`suppliers.${index}.cost`}
                  type="text"
                  className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"></Field>
                <ErrorMessage
                  name={`suppliers.${index}.cost`}
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
              <div className="w-full">
                <label className="mb-2.5 block text-black">Statut</label>
                <Field
                  name={`suppliers.${index}.status`}
                  as="select"
                  className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary">
                  <option value="" label="Select status" />
                  <option value="ENABLED">ACTIVE</option>
                  <option value="DISABLED">INACTIVE</option>
                </Field>
                <ErrorMessage
                  name={`suppliers.${index}.productSupplierName`}
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
              <div className="flex h-full mt-10">
                <button
                  type="button"
                  className="text-gray-1 hover:text-red-700"
                  onClick={() => setDefaultPrice(index, values, values.suppliers[index])}>
                  <LoadingIcon />
                </button>
                <button
                  type="button"
                  className="text-red-500 hover:text-red-700"
                  onClick={() => arrayHelpers.remove(index)}>
                  <TrashIcon />
                </button>
              </div>
            </div>
          ))}
        </>
      )}
    />
  );
};

export default EditProductSupplierForm;
