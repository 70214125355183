import axiosInstance from '../axiosConfig';
const base_url = process.env.REACT_APP_DEVELOP_API;

const supplierService = {
  async getAllSupplier() {
    return axiosInstance.get(`${base_url}/suppliers/`);
  },

  async createSupplier(value: any) {
    return axiosInstance.post(`${base_url}/suppliers/create`, value);
  },

  async updateSupplierById(id: string, value: any) {
    return axiosInstance.patch(`${base_url}/suppliers/update/${id}`, value);
  },

  async deleteSupplier(id: string) {
    return axiosInstance.delete(`${base_url}/suppliers/delete/${id}`);
  },

  async getSupplierById(id: string) {
    return axiosInstance.get(`${base_url}/suppliers/${id}`);
  }
};
export default supplierService;
