import axiosInstance from '../axiosConfig';
const base_url = process.env.REACT_APP_DEVELOP_API;

const supplierService = {
  async getAllSupplierType() {
    return axiosInstance.get(`${base_url}/supplierTypes/`);
  },

};
export default supplierService;
