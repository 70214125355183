import * as Yup from 'yup';

export const AddOrderFormSchema = Yup.object({
  orderReference: Yup.string().required('Le sku du produit est obligatoire'),
  paymentStatus: Yup.string().required('Le nom du produit est obligatoire'),
  totalCost: Yup.string().required('Le type de pierre est obligatoire'),
  status: Yup.string().required('Le statut est obligatoire'),
  supplierId: Yup.string().required('Le statut est obligatoire'),
  quantity: Yup.number()
    .typeError('Le stock doit être un nombre')
    .required('Le stock est obligatoire'),
  expectedAt: Yup.date().required('La date de lancement est obligatoire'),
  products: Yup.array().of(
    Yup.object({
      sku: Yup.string(),
      status: Yup.string(),
      note: Yup.string(),
      quantity: Yup.number().typeError('La quantité doit être un nombre')
    })
  )
});
