import React, { useEffect, useMemo, useState } from 'react';
import { CloseIcon } from '../../../Icon';
import { toast } from 'react-toastify';
import productService from '../../../services/product/Product.service';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import supplierService from '../../../services/supplier/Supplier.service';
import { Product } from '../../../@types/Product.type';
import { EditProductFormSchema } from '../@types/EditProductForm.schema';
import { EditProductInitialValueUtils } from '../utils/ProductInitialValue.utils';
import { productTypeList } from '../utils/ProductTypeList';
import EditProductSupplierCostForm from './EditProductSupplierCostForm';
import PriceSection from './PriceSection';

interface ProductInfoProps {
  onClose: () => void;
  productId: string;
}

const ShowMoreProductsInfo: React.FC<ProductInfoProps> = ({ onClose, productId }) => {
  const [product, setProduct] = useState<Product>();
  const [suppliers, setSuppliers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await productService.getProductById(productId).then((res) => {
          setProduct(res.data);
        });
        await supplierService.getAllSupplier().then((res) => {
          setSuppliers(res.data);
        });
      } catch (e) {
        toast.error('Une erreur est survenu');
      }
    };
    fetchData();
  }, []);

  const initialValues = useMemo(() => {
    if (product) {
      return EditProductInitialValueUtils(product);
    }
  }, [product]);

  const handleSubmit = async (values: any) => {
    try {
      const value: any = { ...values };
      let productUpdated: {
        productSupplierName: string;
        status: number;
        originalId?: string | undefined;
      }[] = [];
      let productDeleted: string[] = [];
      let productCreated: {
        productSupplierName: string;
        status: number;
        serviceType: string;
        originalId?: string | undefined;
      }[] = [];

      value.suppliers.map((productSupplier: any) => {
        if (!productSupplier.originalId) {
          productCreated.push(productSupplier);
        }
      });

      product?.productSupplier?.map((productSupplier: any) => {
        const tmp = value.suppliers.find(
          (element: any) => element.originalId === productSupplier.id
        );
        if (!tmp) {
          productDeleted.push(productSupplier.id);
        } else {
          value.suppliers.find((element: any) => {
            if (element.originalId === productSupplier.id) {
              if (
                element.status != productSupplier.status ||
                element.cost != productSupplier.cost
              ) {
                productUpdated.push(element);
              }
            }
          });
        }
      });

      const finalData = {
        sku: value.sku,
        note: value.note,
        productName: value.productName,
        weight: Number(value?.weight) || 0,
        height: value?.height || 0,
        depth: value?.depth || 0,
        type: value?.type || '',
        stoneType: value?.stoneType || '',
        status: value?.status || '',
        stock: value?.stock || 0,
        size: (value?.size as number) || 0,
        color: value?.color || '',
        retailPrice: value?.retailPrice.toString() || '',
        lunchedAt: value?.lunchedAt?.split('T')[0] || '',
        collectionName: value?.collectionName || '',
        productUpdated,
        productDeleted,
        productCreated
      };
      await productService.updateProduct(productId, finalData).then(() => {
        toast.success('Votre produit a été modifié avec succès');
      });
    } catch (e) {
      toast.error('Une erreur est survenue lors de la mise à jour');
    }
  };

  return (
    <div className="w-full h-full ">
      <button className="absolute top-5 right-2 text-xl" onClick={onClose}>
        <CloseIcon />
      </button>
      {product && initialValues && (
        <Formik
          initialValues={initialValues}
          validationSchema={EditProductFormSchema}
          enableReinitialize={true}
          onSubmit={handleSubmit}>
          {({ dirty, values }) => (
            <Form className="p-6.5">
              <div className="mb-4.5 flex flex-row gap-6">
                <div className="w-1/3">
                  <label className="mb-2.5 block text-black text-sm">Sku</label>
                  <Field
                    name="sku"
                    type="text"
                    disabled={true}
                    placeholder="sku"
                    className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  />
                  <ErrorMessage name="sku" component="div" className="text-red-500 text-sm" />
                </div>
                <div className="w-1/3">
                  <label className="mb-2.5 block text-black text-sm">Nom du produit</label>
                  <Field
                    name="productName"
                    type="text"
                    placeholder="Nom du produit"
                    className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  />
                  <ErrorMessage
                    name="productName"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>
                <div className="w-1/3">
                  <label className="mb-2.5 block text-black text-sm">Date de lancement</label>
                  <Field
                    name="lunchedAt"
                    type="date"
                    min="2000-01-01"
                    max="2040-12-31"
                    className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  />
                  <ErrorMessage name="size" component="div" className="text-red-500 text-sm" />
                </div>
              </div>
              <div className="flex flex-row gap-6">
                <div className="w-1/3">
                  <label className="mb-2.5 block text-black text-sm">Nom de la collection</label>
                  <Field
                    name="collectionName"
                    type="text"
                    placeholder="Nom de la collection"
                    className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  />
                  <ErrorMessage
                    name="collectionName"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>
                <div className="mb-4.5 w-1/3">
                  <label className="mb-2.5 block text-black text-sm">Statut</label>
                  <Field
                    name="status"
                    as="select"
                    placeholder=" "
                    className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary">
                    <option className="w-full h-full" value="ONLINE">
                      ONLINE
                    </option>
                    <option className="w-full h-full" value="OFFLINE">
                      OFFLINE
                    </option>
                  </Field>
                  <ErrorMessage name="status" component="div" className="text-red-500 text-sm" />
                </div>
                <div className="mb-4.5 w-1/3">
                  <label className="mb-2.5 text-black flex justify-between text-sm">Type</label>
                  <Field
                    name="type"
                    as="select"
                    placeholder=" "
                    className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary">
                    <option className="w-full h-full" value="defaultValue">
                      Selectionner un Type de produit
                    </option>
                    {productTypeList.map((productType, index) => (
                      <option className="w-full h-full" value={productType.value} key={index}>
                        {productType.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="type" component="div" className="text-red-500 text-sm" />
                </div>
              </div>

              <div className="flex gap-6 flex-row">
                <div className="mb-4.5 w-full">
                  <label className="mb-2.5 block text-black text-sm">Couleur</label>
                  <Field
                    name="color"
                    type="text"
                    placeholder="Couleur"
                    className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  />
                  <ErrorMessage name="color" component="div" className="text-red-500 text-sm" />
                </div>
                <div className="mb-4.5 w-full">
                  <label className="mb-2.5 block text-black text-sm">Type de pierre </label>
                  <Field
                    name="stoneType"
                    type="text"
                    placeholder="Type de pierre"
                    className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  />
                  <ErrorMessage name="stoneType" component="div" className="text-red-500 text-sm" />
                </div>
                <div className="w-full flex flex-row gap-x-2">
                  <div className="mb-4.5 w-full">
                    <label className="mb-2.5 block text-black text-sm">
                      Hauteur <span className="text-xs">(mm)</span>
                    </label>
                    <Field
                      name="height"
                      type="number"
                      placeholder="0"
                      className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    />
                    <ErrorMessage name="height" component="div" className="text-red-500 text-sm" />
                  </div>
                  <div className="mb-4.5 w-full">
                    <label className="mb-2.5 block text-black text-sm">
                      Largeur<span className="text-xs">(mm)</span>
                    </label>
                    <Field
                      name="size"
                      type="number"
                      className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    />
                    <ErrorMessage name="size" component="div" className="text-red-500 text-sm" />
                  </div>
                  <div className="mb-4.5 w-full">
                    <label className="mb-2.5 block text-black text-sm">
                      Profondeur <span className="text-xs">(mm)</span>
                    </label>
                    <Field
                      name="depth"
                      type="number"
                      placeholder="0"
                      className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    />
                    <ErrorMessage name="depth" component="div" className="text-red-500 text-sm" />
                  </div>
                </div>
              </div>
              <div className="w-full flex-row flex gap-6">
                <div className="mb-4.5 w-full">
                  <label className="mb-2.5 block text-black text-sm">Poids (gr)</label>
                  <Field
                    name="weight"
                    type="number"
                    placeholder="0 Kg"
                    className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  />
                  <ErrorMessage name="weight" component="div" className="text-red-500 text-sm" />
                </div>
                <div className="mb-4.5 w-full">
                  <label className="mb-2.5 text-black flex justify-between text-sm">
                    Prix retail
                  </label>
                  <Field
                    name="retailPrice"
                    type="text"
                    placeholder="Prix"
                    className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  />
                  <ErrorMessage name="type" component="div" className="text-red-500 text-sm" />
                </div>
                <div className="mb-4.5 w-full">
                  <label className="mb-2.5 text-black flex justify-between text-sm">Stock</label>
                  <Field
                    name="stock"
                    disabled={true}
                    type="number"
                    placeholder="NaN"
                    className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  />
                  <ErrorMessage name="stock" component="div" className="text-red-500 text-sm" />
                </div>
              </div>
              <div className="mb-6">
                <label className="mb-2.5 block text-black text-sm">
                  Information complementaire
                </label>
                <Field
                  name="note"
                  as="textarea"
                  rows={2}
                  placeholder="Information complementaire"
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                />
              </div>
              <PriceSection values={values} suppliers={suppliers} />

              <EditProductSupplierCostForm
                values={values}
                suppliers={suppliers}
                initialValues={initialValues}
              />

              {dirty && (
                <button
                  type="submit"
                  onClick={() => console.log('1')}
                  className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray hover:bg-opacity-90">
                  Enregistrer
                </button>
              )}
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default ShowMoreProductsInfo;
