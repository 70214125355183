import * as Yup from 'yup';

export const EditProductFormSchema = Yup.object({
  sku: Yup.string().required('Le sku du produit est obligatoire'),
  productName: Yup.string().required('Le nom du produit est obligatoire'),
  weight: Yup.number(),
  type: Yup.string().required('Le type est obligatoire'),
  stoneType: Yup.string(),
  status: Yup.string(),
  stock: Yup.number(),
  size: Yup.number(),
  lunchedAt: Yup.date(),
  color: Yup.string(),
  note: Yup.string(),
  retailPrice: Yup.number()
});
