import React, { useEffect, useState } from 'react';
import productService from '../../../services/product/Product.service';
import { formatDate } from '../../../utils/formatDate';
import { toast } from 'react-toastify';
import { Product } from '../../../@types/Product.type';
import { EyeIcon, SearchIcon, TrashIcon } from '../../../Icon';
import Modal from '../../../components/Moal';

interface ProductTableProps {
  // eslint-disable-next-line no-unused-vars
  showMore: (id: string) => void;
}
const ProductTable: React.FC<ProductTableProps> = ({ showMore }) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const closeModal = () => setModalOpen(false);

  const modal = (id: string) => {
    setModalOpen(true);
    setSelectedId(id);
  };

  const fetchData = async () => {
    await productService.getAllProduct().then((res) => setProducts(res.data));
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleShowMore = (id: string) => {
    showMore(id);
  };

  const deleteProduct = async (id: string) => {
    try {
      await productService.deleteProductById(id).then(() => {
        toast.success('Produit supprimer correctement');
        fetchData();
        closeModal();
      });
    } catch (e) {
      toast.error('Une erreur est survenu ');
      console.error(e);
    }
  };

  const filteredProducts = products.filter(
    (product) =>
      product.productName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.sku.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default sm:px-7.5 xl:pb-1">
      <Modal
        isOpen={modalOpen}
        onClose={closeModal}
        title="Etes vous sur de vouloir supppimer le Prix">
        <div className="flex justify-end gap-x-3 w-full">
          <button
            className="flex justify-center items-center bg-gray p-2 rounded hover:bg-white hover:border"
            onClick={() => closeModal()}>
            Annuler
          </button>
          <button
            className="flex justify-center items-center bg-gray p-2 rounded hover:bg-white hover:border"
            onClick={() => deleteProduct(selectedId ?? '')}>
            Supprimer
          </button>
        </div>
      </Modal>
      <div className="w-full mx-auto mb-3.5">
        <div className="relative">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <SearchIcon />
          </div>
          <input
            type="search"
            id="default-search"
            onChange={(e) => setSearchTerm(e.target.value)}
            className="block w-full p-4 ps-10 text-sm text-gray-900 border-stroke border rounded-lg bg-gray-50"
            placeholder="Sku, nom"
            required
          />
          <button
            type="submit"
            className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 font-medium rounded-lg text-sm px-4 py-2">
            Recherche
          </button>
        </div>
      </div>
      <div className="max-w-full overflow-x-auto">
        <table className="w-full table-fixed">
          <thead>
            <tr className="bg-gray-2 text-left">
              <th className="min-w-[120px] py-4 px-4 font-medium text-black text-center">Sku</th>
              <th className="min-w-[120px] py-4 px-4 font-medium text-black text-center">Nom</th>
              <th className="min-w-[120px] py-4 px-4 font-medium text-black text-center">
                Date de lancement
              </th>
              <th className="min-w-[120px] py-4 px-4 font-medium text-black  text-center">
                Statut
              </th>
              <th className="min-w-[120px] py-4 px-4 font-medium text-black text-center">Type</th>
              <th className="min-w-[120px] py-4 px-4 font-medium text-black text-center">
                Collection
              </th>
              <th className="py-4 px-4 font-medium text-black text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {products &&
              filteredProducts
                .sort(
                  (a: any, b: any) =>
                    new Date(b.lunchedAt).getTime() - new Date(a.lunchedAt).getTime()
                )
                .map((product: Product, key: number) => (
                  <tr key={key} className="">
                    <td className="border-b border-[#eee] py-5 px-4">
                      <h5 className="font-medium text-black dark:text-white text-center">
                        {product.sku}
                      </h5>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4">
                      <p className="text-sm text-center">{product.productName}</p>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4">
                      <p className="text-black dark:text-white text-center">
                        {formatDate(product.lunchedAt)}
                      </p>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4 text-center">
                      <p
                        className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium text-center ${
                          product.status === 'ONLINE'
                            ? 'bg-success text-success'
                            : product.status === 'OFFLINE'
                              ? 'bg-danger text-danger'
                              : 'bg-warning text-warning'
                        }`}>
                        {product.status}
                      </p>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4">
                      <h5 className="font-medium text-black dark:text-white text-center">
                        {product?.type}
                      </h5>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4">
                      <h5 className="font-medium text-black dark:text-white text-center">
                        {product?.collectionName}
                      </h5>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4">
                      <div className="flex items-center space-x-3.5 justify-center">
                        <button
                          className="hover:text-primary"
                          onClick={() => handleShowMore(product.sku)}>
                          <EyeIcon />
                        </button>
                        <button className="hover:text-primary" onClick={() => modal(product.sku)}>
                          <TrashIcon />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductTable;
