import * as Yup from 'yup';

export const EditSupplierValidationSchema = Yup.object({
  name: Yup.string().required('Nom du fournisseur est requis'),
  type: Yup.object(),
  location: Yup.string().required('Adresse est requise'),
  contact1: Yup.string().required('Contact 1 est requis'),
  paymentTerms: Yup.string(),
  contact2: Yup.string(),
  contact3: Yup.string(),
  note: Yup.string()
});
