import { Cost } from '../../../@types/Cost.type';

export const AddCostInitialValueUtils = {
  price: '',
  supplier: '',
  unit: 'gr',
  serviceType: ''
};

export const EditCostInitialValueUtils = (cost: Cost) => {
  return {
    price: cost?.price || '',
    supplier: cost?.supplier?.id || '',
    serviceType: cost?.serviceType || '',
    unit: cost?.unit || ''
  };
};
