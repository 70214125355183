import React, { useEffect, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import supplierService from '../../../services/supplier/Supplier.service';
import { toast } from 'react-toastify';
import productService from '../../../services/product/Product.service';
import { AddProductFormSchema } from '../@types/AddProductForm.schema';
import { AddProductInitialValueUtils } from '../utils/ProductInitialValue.utils';
import { productTypeList } from '../utils/ProductTypeList';
import ProductSupplierForm from './ProductSupplierForm';
import PriceSection from './PriceSection';

interface ProductModalProps {
  onClose: () => void;
}

const AddProductForm: React.FC<ProductModalProps> = ({ onClose }) => {
  const [suppliers, setSuppliers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const tmp = await supplierService.getAllSupplier();
      setSuppliers(tmp.data);
    };
    fetchData();
  }, []);

  const submitSupplier = async (values: any) => {
    try {
      await productService.createProduct(values).then(() => {
        toast.success('Votre compte a ete modifié avec succes');
        onClose();
      });
    } catch (e) {
      console.error(e);
      toast.error("Une erreur s'est produite veuillez recommencer plus tard.");
      onClose();
    }
  };

  return (
    <div className="flex flex-col gap-9">
      <div className="rounded-sm shadow-default">
        <Formik
          initialValues={AddProductInitialValueUtils}
          validationSchema={AddProductFormSchema}
          enableReinitialize={true}
          onSubmit={submitSupplier}>
          {({ values }) => (
            <Form className="p-6.5">
              <div className="mb-4.5 flex flex-row gap-6">
                <div className="w-1/3">
                  <label className="mb-2.5 block text-black text-sm">Sku</label>
                  <Field
                    name="sku"
                    type="text"
                    placeholder="sku"
                    className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  />
                  <ErrorMessage name="sku" component="div" className="text-red-500 text-sm" />
                </div>
                <div className="w-1/3">
                  <label className="mb-2.5 block text-black text-sm">Nom du produit</label>
                  <Field
                    name="productName"
                    type="text"
                    placeholder="Nom du produit"
                    className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  />
                  <ErrorMessage
                    name="productName"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>
                <div className="w-1/3">
                  <label className="mb-2.5 block text-black text-sm">Date de lancement</label>
                  <Field
                    name="lunchedAt"
                    type="date"
                    min="2000-01-01"
                    max="2040-12-31"
                    className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  />
                  <ErrorMessage name="size" component="div" className="text-red-500 text-sm" />
                </div>
              </div>
              <div className="flex flex-row gap-6">
                <div className="w-1/3">
                  <label className="mb-2.5 block text-black text-sm">Nom de la collection</label>
                  <Field
                    name="collectionName"
                    type="text"
                    placeholder="Nom de la collection"
                    className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  />
                  <ErrorMessage
                    name="collectionName"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>
                <div className="mb-4.5 w-1/3">
                  <label className="mb-2.5 block text-black text-sm">Statut</label>
                  <Field
                    name="status"
                    as="select"
                    placeholder=" "
                    className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary">
                    <option className="w-full h-full" value="ONLINE">
                      ONLINE
                    </option>
                    <option className="w-full h-full" value="OFFLINE">
                      OFFLINE
                    </option>
                  </Field>
                  <ErrorMessage name="status" component="div" className="text-red-500 text-sm" />
                </div>
                <div className="mb-4.5 w-1/3">
                  <label className="mb-2.5 text-black flex justify-between text-sm">Type</label>
                  <Field
                    name="type"
                    as="select"
                    placeholder=" "
                    className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary">
                    <option className="w-full h-full" value="defaultValue">
                      Selectionner un Type de produit
                    </option>
                    {productTypeList.map((productType, index) => (
                      <option className="w-full h-full" value={productType.value} key={index}>
                        {productType.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="type" component="div" className="text-red-500 text-sm" />
                </div>
              </div>

              <div className="flex gap-6 flex-row">
                <div className="mb-4.5 w-full">
                  <label className="mb-2.5 block text-black text-sm">Couleur</label>
                  <Field
                    name="color"
                    type="text"
                    placeholder="Couleur"
                    className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  />
                  <ErrorMessage name="color" component="div" className="text-red-500 text-sm" />
                </div>
                <div className="mb-4.5 w-full">
                  <label className="mb-2.5 block text-black text-sm">Type de pierre </label>
                  <Field
                    name="stoneType"
                    type="text"
                    placeholder="Type de pierre"
                    className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  />
                  <ErrorMessage name="stoneType" component="div" className="text-red-500 text-sm" />
                </div>

                <div className="w-full flex flex-row gap-x-3">
                  <div className="w-full">
                    <label className="block text-black text-xs mb-3.5">Hauteur (mm)</label>
                    <Field
                      name="height"
                      type="number"
                      placeholder="0"
                      className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    />
                    <ErrorMessage name="height" component="div" className="text-red-500 text-sm" />
                  </div>
                  <div className="w-full">
                    <label className="block text-black text-xs mb-3.5">Largeur (mm)</label>
                    <Field
                      name="size"
                      type="number"
                      placeholder="0"
                      className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    />
                    <ErrorMessage name="size" component="div" className="text-red-500 text-sm" />
                  </div>
                  <div className="w-full">
                    <label className="block text-black text-xs mb-3.5">Profondeur (mm)</label>
                    <Field
                      name="depth"
                      type="number"
                      placeholder="0"
                      className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    />
                    <ErrorMessage name="depth" component="div" className="text-red-500 text-sm" />
                  </div>
                </div>
              </div>
              <div className="w-full flex-row flex gap-6">
                <div className="mb-4.5 w-full">
                  <label className="mb-2.5 block text-black text-sm">Poids (gr)</label>
                  <Field
                    name="weight"
                    type="number"
                    placeholder="0 Kg"
                    className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  />
                  <ErrorMessage name="weight" component="div" className="text-red-500 text-sm" />
                </div>
                <div className="mb-4.5 w-full">
                  <label className="mb-2.5 text-black flex justify-between text-sm">
                    Prix retail
                  </label>
                  <Field
                    name="retailPrice"
                    type="text"
                    placeholder="Prix"
                    className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  />
                  <ErrorMessage
                    name="retailPrice"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>
                <div className="mb-4.5 w-full">
                  <label className="mb-2.5 text-black flex justify-between text-sm">Stock</label>
                  <Field
                    name="stock"
                    disabled={true}
                    type="number"
                    placeholder="NaN"
                    className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  />
                  <ErrorMessage name="stock" component="div" className="text-red-500 text-sm" />
                </div>
              </div>

              <div className="mb-6">
                <label className="mb-2.5 block text-black text-sm">
                  Information complementaire
                </label>
                <Field
                  name="note"
                  as="textarea"
                  rows={2}
                  placeholder="Information complementaire"
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                />
              </div>
              <PriceSection values={values} suppliers={suppliers} />
              <ProductSupplierForm values={values} suppliers={suppliers} />
              <button
                type="submit"
                className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray hover:bg-opacity-90">
                Ajouter le produit
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddProductForm;
