import React from 'react';

const LoadingSpinner: React.FC = () => {
  return (
    <div className="w-full min-h-56 flex flex-col items-center justify-center bg-white bg-opacity-80 z-50">
      <div className="border-t-4 border-graydark border-dotted rounded-full w-20 h-20 animate-spin"></div>
      <span className="ml-5">Récuperation en cours</span>
    </div>
  );
};

export default LoadingSpinner;
