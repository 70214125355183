import React, { useState } from 'react';

import DefaultLayout from '../../components/layout/DefaultLayout';
import ProductTable from './components/ProductTable';
import AddProductForm from './components/AddProductForm';
import ShowMoreProductInfo from './components/ShowMoreProductInfo';
import { ArrowLeftIcon } from '../../Icon';
const ProductPage: React.FC = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [sidePageOpen, setSidePageOpen] = useState<boolean>(false);
  const [productDataId, setProductDataId] = useState<string>('');

  const closeModal = () => {
    setModalOpen(false);
  };

  const closeSide = () => {
    setSidePageOpen(false);
  };
  const displayMore = (id: string) => {
    setSidePageOpen(!sidePageOpen);
    setProductDataId(id);
  };
  return (
    <DefaultLayout>
      <div className="w-full flex justify-between mb-2">
        <span className="p-2 text-lg">{!sidePageOpen ? 'Produits' : 'Détails du produit'}</span>
        {!sidePageOpen && !modalOpen && (
          <button
            className="flex justify-center items-center bg-gray p-2 rounded hover:bg-white hover:border"
            onClick={() => setModalOpen(true)}>
            Ajouter un produit
          </button>
        )}
        {modalOpen && (
          <button
            className="flex justify-center items-center bg-gray p-2 rounded hover:bg-white hover:border"
            onClick={() => setModalOpen(false)}>
            <ArrowLeftIcon />
          </button>
        )}
      </div>

      {!modalOpen && !sidePageOpen && <ProductTable showMore={displayMore} />}
      {modalOpen && <AddProductForm onClose={closeModal} />}
      {sidePageOpen && productDataId && (
        <ShowMoreProductInfo onClose={closeSide} productId={productDataId} />
      )}
    </DefaultLayout>
  );
};

export default ProductPage;
