import React, { useEffect, useMemo } from 'react';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import { ProductOrder } from '../../../@types/Order.type';

interface GlobalQuantityProps {
  values: any;
}

const GlobalQuantity: React.FC<GlobalQuantityProps> = ({ values }) => {
  const { setFieldValue } = useFormikContext();

  const global = useMemo(() => {
    return values.products.reduce((accumulator: number, productOrder: ProductOrder) => {
      return accumulator + productOrder.quantity;
    }, 0);
  }, [values.products]);

  useEffect(() => {
    setFieldValue('quantity', global ?? 0);
  }, [global, setFieldValue]);

  return (
    <div className="mb-4.5 w-full">
      <label className="mb-2.5 block text-black dark:text-white">Quantité</label>
      <Field
        name="quantity"
        type="number"
        disabled={true}
        placeholder="Quantité"
        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 h-12 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
      />
      <ErrorMessage name="quantity" component="div" className="text-red-500 text-sm" />
    </div>
  );
};

export default GlobalQuantity;
