import { Supplier } from '../../../@types/Supplier.type';

export const AddSupplierInitialValueUtils = {
  name: '',
  type: 'Selectionner un type',
  location: '',
  paymentTerms: '',
  contact1: '',
  contact2: '',
  contact3: '',
  note: ''
};

export const EditSupplierInitialValueUtils = (supplier: Supplier) => {
  return {
    name: supplier.name || '',
    type: supplier.type || '',
    location: supplier.location || '',
    paymentTerms: supplier.paymentTerms || '',
    contact1: supplier.contact1 || '',
    contact2: supplier.contact2 || '',
    contact3: supplier.contact3 || '',
    note: supplier.note || ''
  };
};
