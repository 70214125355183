import React, { useEffect, useState } from 'react';
import { Supplier } from '../../../@types/Supplier.type';
import costService from '../../../services/cost/Cost.service';
import { Cost } from '../../../@types/Cost.type';

interface PriceSectionProps {
  values: any;
  suppliers: Supplier[];
}

const PriceSection: React.FC<PriceSectionProps> = ({ values }) => {
  const [productCost, setProductCost] = useState<number>(0);
  const [costs, setCosts] = useState<Cost[]>([]);
  const [packedCost, setPackedCost] = useState<number>(0);
  const [shippedCost, setShippedCost] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await costService.getAllCoast().then((res) => setCosts(res.data));
      } catch (error) {
        console.error('Error fetching costs:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filteredPackingCosts = costs.filter((cost: Cost) =>
      ['sentPouch', 'sentFlyer', 'sentPackaging'].includes(cost.serviceType)
    );
    const filteredShippingCosts = costs.filter((cost: Cost) =>
      ['shipping'].includes(cost.serviceType)
    );
    const packagingCost = filteredPackingCosts.reduce((accumulator, cost) => {
      return accumulator + (parseFloat(cost.price) || 0);
    }, 0);

    const shippingCost = filteredShippingCosts.reduce((accumulator, cost) => {
      return accumulator + (parseFloat(cost.price) || 0);
    }, 0);

    const totalProductCost = values.suppliers.reduce(
      (accumulator: number, supplier: { status: string; cost: any }) => {
        if (supplier.status === 'ENABLED') {
          return accumulator + (parseFloat(supplier.cost) || 0);
        }
        return accumulator;
      },
      0
    );
    setShippedCost(shippingCost);
    setProductCost(totalProductCost);
    setPackedCost(parseFloat(totalProductCost) + packagingCost);
  }, [values.suppliers, costs]);

  return (
    <div className="w-full flex flex-col py-4">
      <div className="w-full border-b border-gray-300 pb-2 mb-4">
        <h2 className="text-lg font-semibold">Détails du Prix du Produit</h2>
      </div>
      <div className="mb-2 flex gap-x-3">
        <div className="w-full">
          <label className="mb-2.5 block text-black text-sm">Coût du produit :</label>
          <div className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter">
            {productCost?.toFixed(2)} €
          </div>
        </div>
        <div className="w-full">
          <label className="mb-2.5 block text-black text-sm">Packed cost :</label>
          <div className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter">
            {packedCost?.toFixed(2)} €
          </div>
        </div>
      </div>
      <div className="mb-2 flex gap-x-3">
        <div className="w-full">
          <label className="mb-2.5 block text-black text-sm">Prix de base :</label>
          <div className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter">
            {(((packedCost * 2.5) / 0.7) * 1.2).toFixed(2)} €
          </div>
        </div>
        <div className="w-full">
          <label className="mb-2.5 block text-black text-sm">Prix élevé :</label>
          <div className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter">
            {packedCost && (packedCost * 5).toFixed(2)} €
          </div>
        </div>
      </div>
      <div className="mb-2 flex gap-x-3">
        <div className="w-full">
          <label className="mb-2.5 block text-black text-sm">Shipped cost :</label>
          <div className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter">
            {packedCost && (shippedCost + packedCost).toFixed(2)} €
          </div>
        </div>
        <div className="w-full">
          <label className="mb-2.5 block text-black text-sm">Marge :</label>
          <div className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter">
            {(values.retailPrice / packedCost)?.toFixed(2)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceSection;
