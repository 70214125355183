import React, { useEffect, useState } from 'react';
import supplierService from '../../../services/supplier/Supplier.service';
import { toast } from 'react-toastify';
import { formatDate } from '../../../utils/formatDate';
import { Supplier } from '../../../@types/Supplier.type';
import { EyeIcon, SearchIcon, TrashIcon } from '../../../Icon';

interface SupplierTableProps {
  // eslint-disable-next-line no-unused-vars
  showMore: (id: string) => void;
}

const SupplierTable: React.FC<SupplierTableProps> = ({ showMore }) => {
  const [suppliers, setSuppliers] = useState<Supplier[]>();
  const [searchTerm, setSearchTerm] = useState('');

  const fetchData = async () => {
    await supplierService.getAllSupplier().then((res) => {
      setSuppliers(res.data);
    });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleShowMore = (id: string) => {
    showMore(id); // Ensure that the id is passed to showMore function here
  };

  const deleteSupplier = async (id: string) => {
    try {
      await supplierService.deleteSupplier(id).then(() => {
        toast.success('Supplier supprimer correctement');
        fetchData();
      });
    } catch (e) {
      toast.error('Une erreur est survenu ');
      console.error(e);
    }
  };

  const filteredSuppliers = suppliers?.filter((supplier) =>
    supplier.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default sm:px-7.5 xl:pb-1">
      <div className="w-full mx-auto mb-3.5">
        <div className="relative">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <SearchIcon />
          </div>
          <input
            type="search"
            id="default-search"
            onChange={(e) => setSearchTerm(e.target.value)}
            className="block w-full p-4 ps-10 text-sm text-gray-900 border-stroke border rounded-lg bg-gray-50"
            placeholder="Nom"
            required
          />
          <button
            type="submit"
            className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 font-medium rounded-lg text-sm px-4 py-2">
            Recherche
          </button>
        </div>
      </div>
      <div className="max-w-full overflow-x-auto">
        <table className="w-full table-auto">
          <thead>
            <tr className="bg-gray-2 text-left">
              <th className="min-w-[220px] py-4 px-4 font-medium text-black text-center">Nom</th>
              <th className="min-w-[150px] py-4 px-4 font-medium text-black text-center">Type</th>
              <th className="min-w-[120px] py-4 px-4 font-medium text-black text-center">
                Adresse
              </th>
              <th className="py-4 px-4 font-medium text-black text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {suppliers &&
              filteredSuppliers?.map((supplier: Supplier, key: number) => (
                <tr key={key}>
                  <td
                    className="border-b border-[#eee] py-5 px-4 pl-9 text-center cursor-pointer"
                    onClick={() => handleShowMore(supplier.id)}>
                    <h5 className="font-medium text-black">{supplier.name}</h5>
                    <p className="text-sm">{formatDate(supplier.createdAt)}</p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 text-center">
                    <p className="text-black dark:text-white">{supplier.type.type}</p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 text-center">
                    <p
                      className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium`}>
                      {supplier.location}
                    </p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4">
                    <div className="flex items-center space-x-3.5 justify-center">
                      <button
                        className="hover:text-primary"
                        onClick={() => handleShowMore(supplier.id)}>
                        <EyeIcon />
                      </button>
                      <button
                        className="hover:text-primary"
                        onClick={() => deleteSupplier(supplier.id)}>
                        <TrashIcon />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SupplierTable;
