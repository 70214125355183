import React from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import ErrorPage from './pages/ErrorPage';
import LoginPage from './pages/Login/LoginPage';
import { RecoilRoot } from 'recoil';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';

import RequireAuth from './components/RequireAuth';
import { AuthProvider } from './context/AuthProvider';
import ProductPage from './pages/Product/ProducPage';
import SupplierPage from './pages/Supplier/SupplierPage';
import OrderPage from './pages/Order/OrderPage';
import CostPage from './pages/Cost/CostPage';
import StockPage from './pages/Stock/StockPage';
import { RequireStatus } from './components/RequireStatus';
import UserPage from './pages/User/UserPage';

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Navigate to="/login" replace />,
      errorElement: <ErrorPage />
    },
    {
      path: '/error',
      element: <ErrorPage />
    },
    {
      path: '/login',
      element: <LoginPage />,
      errorElement: <ErrorPage />
    },

    {
      element: <RequireAuth />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: '/orders',
          element: <OrderPage />,
          errorElement: <ErrorPage />
        },
        {
          path: '/products',
          element: <ProductPage />,
          errorElement: <ErrorPage />
        },
        {
          path: '/supplier',
          element: <SupplierPage />,
          errorElement: <ErrorPage />
        },
        {
          path: '/costs',
          element: <CostPage />,
          errorElement: <ErrorPage />
        },
        {
          path: '/stock',
          element: <StockPage />,
          errorElement: <ErrorPage />
        },
        {
          element: <RequireStatus status="ADMIN" />,
          errorElement: <ErrorPage />,
          children: [
            {
              path: '/users',
              element: <UserPage />,
              errorElement: <ErrorPage />
            }
          ]
        }
      ]
    }
  ]);

  return (
    <RecoilRoot>
      <div>
        <ToastContainer />
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </div>
    </RecoilRoot>
  );
}

export default App;
