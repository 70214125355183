import axiosInstance from '../axiosConfig';
const base_url = process.env.REACT_APP_DEVELOP_API;

const costService = {
  async getAllCoast() {
    return axiosInstance.get(`${base_url}/costs/`);
  },

  async getCostById(id: string) {
    return axiosInstance.get(`${base_url}/costs/${id}`);
  },
  async createCost(data: any) {
    return axiosInstance.post(`${base_url}/costs/create`, { data });
  },

  async updateCost(id: string, data: any) {
    console.log(data, 'lala');
    return axiosInstance.patch(`${base_url}/costs/update/${id}`, data);
  },
  async deleteCostById(id: string) {
    return axiosInstance.delete(`${base_url}/costs/delete/${id}`);
  }
};
export default costService;
