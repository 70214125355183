import axiosInstance from '../axiosConfig';
const base_url = process.env.REACT_APP_DEVELOP_API;

const orderService = {
  async getAllOrder() {
    return axiosInstance.get(`${base_url}/orders/`);
  },

  async getOrderById(id: string) {
    return axiosInstance.get(`${base_url}/orders/${id}`);
  },
  async createOrder(data: any) {
    return axiosInstance.post(`${base_url}/orders/create`, data);
  },

  async updateOrder(id: string, data: any) {
    return axiosInstance.patch(`${base_url}/orders/update/${id}`, data);
  },

  async duplicateOrder(data: any) {
    return axiosInstance.post(`${base_url}/orders/duplicateOrder`, data);
  },
  async deleteOrderById(id: string) {
    return axiosInstance.delete(`${base_url}/orders/delete/${id}`);
  }
};
export default orderService;
