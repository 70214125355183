import React, { useEffect, useMemo } from 'react';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import { ProductOrder } from '../../../@types/Order.type';

interface GlobalCostProps {
  values: any;
}

const GlobalCost: React.FC<GlobalCostProps> = ({ values }) => {
  const { setFieldValue } = useFormikContext();

  const global = useMemo(() => {
    return values.products.reduce((accumulator: number, productOrder: ProductOrder) => {
      return accumulator + (Number(productOrder?.cost) || 0);
    }, 0);
  }, [values.products]);

  useEffect(() => {
    setFieldValue('totalCost', global ?? '');
  }, [global, setFieldValue]);

  return (
    <div className="mb-4.5 w-full">
      <label className="mb-2.5 block text-black">Cout Total €</label>
      <Field
        name="totalCost"
        disabled={true}
        type="text"
        placeholder="Cout Total"
        className="w-full rounded border-[1.5px] border-stroke bg-transparent h-12 py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
      />
      <ErrorMessage name="totalCost" component="div" className="text-red-500 text-sm" />
    </div>
  );
};

export default GlobalCost;
