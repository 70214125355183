import React, { useEffect, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import supplierService from '../../../services/supplier/Supplier.service';
import { toast } from 'react-toastify';
import { AddCostFormSchema } from '../@types/AddCostForm.schema';
import { AddCostInitialValueUtils } from '../utils/CostInitialValue.utils';
import { Supplier } from '../../../@types/Supplier.type';
import costService from '../../../services/cost/Cost.service';
import { productServiceTypeList } from '../../Product/utils/ProductTypeList';
interface CostFormProps {
  onClose: () => void;
}

const AddCostForm: React.FC<CostFormProps> = ({ onClose }) => {
  const [suppliers, setSuppliers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await supplierService.getAllSupplier().then((res) => setSuppliers(res.data));
    };
    fetchData();
  }, []);

  const submitSupplier = async (values: any) => {
    try {
      await costService.createCost(values).then(() => {
        toast.success('Votre prix a été creer avec  succes');
        onClose();
      });
    } catch (e) {
      toast.error("Une erreur s'est produite veuillez recommencer plus tard.");
      onClose();
    }
  };

  return (
    <div className="flex flex-col gap-9">
      <div className="rounded-sm shadow-default">
        <Formik
          initialValues={AddCostInitialValueUtils}
          validationSchema={AddCostFormSchema}
          onSubmit={submitSupplier}>
          <Form className="p-6.5">
            <div className="mb-4.5 flex flex-row gap-6">
              <div className="w-full">
                <label className="mb-2.5 block text-black text-sm">Price</label>
                <Field
                  name="price"
                  type="text"
                  placeholder="price"
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                />
                <ErrorMessage name="price" component="div" className="text-red-500 text-sm" />
              </div>

              <div className="w-full">
                <label className="mb-2.5 block text-black text-sm">Unité</label>
                <Field
                  name="unit"
                  as="select"
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary">
                  <option className="w-full h-full" value="gr">
                    gr
                  </option>
                  <option className="w-full h-full" value="mm2">
                    mm2 (surface)
                  </option>
                  <option className="w-full h-full" value="unit">
                    unit
                  </option>
                </Field>
                <ErrorMessage name="unit" component="div" className="text-red-500 text-sm" />
              </div>
            </div>
            <div className="mb-4.5 flex flex-row gap-6">
              <div className="w-full">
                <label className="mb-2.5 block text-black text-sm">Prestation</label>
                <Field
                  name="serviceType"
                  as="select"
                  placeholder="service"
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary">
                  <option className="w-full h-full" value="defaultValue">
                    Selectionner un Type de prestation
                  </option>
                  {productServiceTypeList.map((serviceType, index) => (
                    <option key={index} value={serviceType?.value}>
                      {serviceType?.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="serviceType" component="div" className="text-red-500 text-sm" />
              </div>
              <div className="w-full">
                <label className="mb-2.5 block text-black text-sm">Fournisseur</label>
                <Field
                  name="supplier"
                  as="select"
                  placeholder=" "
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary">
                  <option className="w-full h-full" value="defaultValue">
                    Selectionner un fourniseur
                  </option>
                  {suppliers?.map((supplier: Supplier, index: number) => (
                    <option className="w-full h-full" value={supplier.id} key={index}>
                      {supplier.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="price" component="div" className="text-red-500 text-sm" />
              </div>
            </div>
            <button
              type="submit"
              className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray hover:bg-opacity-90">
              Ajouter un prix
            </button>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default AddCostForm;
