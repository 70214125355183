export const AddOrderInitialValueUtils = {
  orderReference: '',
  paymentStatus: 'IN_PROGRESS',
  status: 'IN_PROGRESS',
  totalCost: '',
  supplierId: '',
  quantity: '',
  expectedAt: '',
  products: []
};

export const EditOrderInitialValueUtils = (order: any, productOrder: any) => {
  return {
    orderReference: order?.orderReference || '',
    paymentStatus: order?.paymentStatus || '',
    status: order?.status || '',
    totalCost: (order?.totalCost as string) || '',
    quantity: order?.quantity || '',
    supplierId: order?.supplier?.id || '',
    expectedAt: order?.expectedAt.split('T')[0] || '',
    products: productOrder
      ? productOrder.map((tmp: any) => {
          return {
            sku: tmp?.product?.sku,
            status: tmp?.status,
            note: tmp?.note,
            quantity: tmp.quantity,
            originalId: tmp.id,
            cost: 0
          };
        }) || []
      : order?.productOrders.map((productsOrder: any) => {
          return {
            sku: productsOrder?.product?.sku,
            status: productsOrder?.status,
            note: productsOrder?.note,
            quantity: productsOrder.quantity,
            originalId: productsOrder.id,
            cost: 0
          };
        }) || []
  };
};
