import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { Product } from '../../../@types/Product.type';

interface ProductsOrderCostProps {
  values: any;
  index: number;
  productOrder: any;
  products: Product[];
}

const ProductsOrderCost: React.FC<ProductsOrderCostProps> = ({
  values,
  index,
  productOrder,
  products
}) => {
  const { setFieldValue } = useFormikContext();
  const [isNaN, setIsNaN] = useState<boolean>(false);

  useEffect(() => {
    if (productOrder && productOrder.sku) {
      const product = products.find((element) => element.sku === productOrder.sku);
      const tmp = product?.productSupplier?.find(
        (element) => element.productSupplierName === values.supplierId
      );

      if (tmp) {
        const final = (tmp?.cost ?? 0) * productOrder.quantity;
        setFieldValue(`products.${index}.cost`, final ?? 0);
        setIsNaN(false);
      } else {
        setFieldValue(`products.${index}.cost`, 'NaN');
        setIsNaN(true);
      }
    }
  }, [values]);

  return (
    <div className="w-full">
      <label className="mb-2.5 block text-black">
        Prix €{' '}
        {isNaN && <span className="text-red-500 text-xs z-9999"> Attention prix non definie </span>}
      </label>
      <Field
        name={`products.${index}.cost`}
        type="text"
        disabled={true}
        className="w-full h-12 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black active:border-primary disabled:cursor-default disabled:bg-whiter"></Field>
    </div>
  );
};

export default ProductsOrderCost;
