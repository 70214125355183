import React, { useEffect, useState } from 'react';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { toast } from 'react-toastify';
import { AddOrderInitialValueUtils } from '../utils/OrderInitialValue.utils';
import { AddOrderFormSchema } from '../@type/AddOrderForm.schema';
import orderService from '../../../services/order/Order.service';
import productService from '../../../services/product/Product.service';
import { DownloadIcon, TrashIcon } from '../../../Icon';
import Papa from 'papaparse';
import { Supplier } from '../../../@types/Supplier.type';
import { Product } from '../../../@types/Product.type';
import supplierService from '../../../services/supplier/Supplier.service';
import GlobalQuantity from './GlobalQuantity';
import GlobalCost from './GlobalCost';
import ProductsOrderCost from './ProductsOrderCost';
const AddOrderForm: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      await productService.getAllProduct().then((res) => setProducts(res.data));
      await supplierService.getAllSupplier().then((res) => setSuppliers(res.data));
    };
    fetchData();
  }, []);

  const submitSupplier = async (values: any) => {
    try {
      const value = { ...values };
      await orderService.createOrder(value).then(() => {
        toast.success('Votre commande a été ajoutée avec succès');
        location.reload();
      });
    } catch (e) {
      toast.error("Une erreur s'est produite, veuillez réessayer plus tard.");
    }
  };

  return (
    <div className="flex flex-col gap-9">
      <div className="rounded-sm shadow-default">
        <Formik
          initialValues={AddOrderInitialValueUtils}
          validationSchema={AddOrderFormSchema}
          onSubmit={submitSupplier}>
          {({ values }) => (
            <Form className="p-6.5">
              <div className="mb-4.5 flex flex-row gap-6">
                <div className="w-1/3">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Référence commande
                  </label>
                  <Field
                    name="orderReference"
                    type="text"
                    placeholder="Reference commande"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 h-12 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  />
                  <ErrorMessage
                    name="orderReference"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>
                <div className="w-1/3">
                  <label className="mb-2.5 block text-black">Statut</label>
                  <Field
                    name="status"
                    as="select"
                    className="w-full rounded border-[1.5px] border-stroke h-12 bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary">
                    <option value="IN_PROGRESS">En prod</option>
                    <option value="DONE">Reçu</option>
                    <option value="PARTIAL">Partiellement Reçu</option>
                  </Field>
                  <ErrorMessage name="status" component="div" className="text-red-500 text-sm" />
                </div>
                <div className="w-1/3">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Livraison estimée
                  </label>
                  <Field
                    name="expectedAt"
                    type="date"
                    min="2000-01-01"
                    max="2040-12-31"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent h-12 py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  />
                  <ErrorMessage
                    name="expectedAt"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>
              </div>

              <div className="flex gap-6 flex-row">
                <div className="w-full">
                  <label className="mb-2.5 block text-black">Founisseur</label>
                  <Field
                    name="supplierId"
                    as="select"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent h-12 py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary">
                    <option value="" label="Select founisseur" />
                    {suppliers.map((supplier: Supplier, idx) => (
                      <option key={idx} value={supplier.id}>
                        {supplier.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="products.supplierId"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>
                <GlobalQuantity values={values} />
                <GlobalCost values={values} />
                <div className="mb-4.5 w-full">
                  <label className="mb-2.5 block text-black">Paiement</label>
                  <Field
                    name="paymentStatus"
                    as="select"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent h-12 py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary">
                    <option value="IN_PROGRESS">Acompte</option>
                    <option value="DONE">Payé</option>
                    <option value="DECLINED">Non payé</option>
                  </Field>
                  <ErrorMessage
                    name="paymentStatus"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>
              </div>

              <FieldArray
                name="products"
                render={(arrayHelpers) => (
                  <>
                    <div className="flex w-full justify-between border-b mb-3 border-stroke">
                      <span className="flex p-2">Ajouter un produit à la commande</span>
                      <div className="flex gap-x-2">
                        <label
                          htmlFor="myInputFile"
                          className="mb-4 p-2 bg-gray text-black rounded flex gap-x-3 items-center">
                          <DownloadIcon />
                          Import produits
                          <input
                            type="file"
                            id="myInputFile"
                            accept=".csv"
                            style={{ display: 'none' }}
                            onChange={(event) => {
                              const files = event?.target?.files;
                              if (files) {
                                Papa.parse(files[0], {
                                  header: true,
                                  skipEmptyLines: true,
                                  complete: function (results) {
                                    results.data.forEach((prod: any) => {
                                      arrayHelpers.push({
                                        sku: prod.sku,
                                        quantity: prod.quantity,
                                        status: 'IN_PROGRESS',
                                        note: prod.note
                                      });
                                    });
                                  }
                                });
                              }
                            }}
                          />
                        </label>
                        <button
                          type="button"
                          disabled={!values.supplierId}
                          className="mb-4 p-2 bg-primary text-white rounded disabled:opacity-50"
                          onClick={() =>
                            arrayHelpers.push({
                              sku: '',
                              quantity: '',
                              status: 'IN_PROGRESS',
                              note: '',
                              cost: 0
                            })
                          }>
                          Add Product
                        </button>
                      </div>
                    </div>

                    {values.products.map((product: any, index) => (
                      <div key={index} className="flex flex-row gap-x-3 mb-4.5 items-center h-full">
                        <div className="w-full">
                          <label className="mb-2.5 block text-black">Sku</label>
                          <Field
                            name={`products.${index}.sku`}
                            as="select"
                            className="w-full rounded border-[1.5px] border-stroke bg-transparent h-12 py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary">
                            <option value="" label="Select SKU" />
                            {products.map((product: any, idx) => (
                              <option key={idx} value={product.sku}>
                                {product.sku} - {product.productName}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name={`products.${index}.sku`}
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </div>
                        <div className="w-full">
                          <label className="mb-2.5 block text-black">Quantité</label>
                          <Field
                            name={`products.${index}.quantity`}
                            type="number"
                            placeholder="Quantité"
                            className="w-full rounded border-[1.5px] border-stroke bg-transparent h-12 py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                          />
                          <ErrorMessage
                            name={`products.${index}.quantity`}
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </div>
                        <div className="w-full">
                          <label className="mb-2.5 block text-black">Statut</label>
                          <Field
                            name={`products.${index}.status`}
                            as="select"
                            className="w-full rounded border-[1.5px] border-stroke bg-transparent h-12 py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary">
                            <option value="PARTIALLY_DONE">Partiellement reçu</option>
                            <option value="IN_PROGRESS">En production</option>
                            <option value="DONE">Reçu</option>
                          </Field>
                          <ErrorMessage
                            name={`products.${index}.status`}
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </div>
                        <div className="w-full">
                          <label className="mb-2.5 block text-black">Note</label>
                          <Field
                            name={`products.${index}.note`}
                            type="text"
                            placeholder="Note"
                            className="w-full rounded border-[1.5px] border-stroke bg-transparent h-12 py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                          />
                          <ErrorMessage
                            name={`products.${index}.note`}
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </div>
                        <ProductsOrderCost
                          values={values}
                          products={products}
                          index={index}
                          productOrder={values.products[index]}
                        />
                        <div className="flex h-full mt-10">
                          <button
                            type="button"
                            className="text-red-500 hover:text-red-700"
                            onClick={() => arrayHelpers.remove(index)}>
                            <TrashIcon />
                          </button>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              />

              <button
                type="submit"
                className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray hover:bg-opacity-90 ">
                Ajouter la commande
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddOrderForm;
