import axiosInstance from '../axiosConfig';

const base_url = process.env.REACT_APP_DEVELOP_API;

const stockService = {
  async getAllStock() {
    const url = `${base_url}/stock`;
    return axiosInstance.get(url);
  }
};

export default stockService;
