import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { EyeIcon, SearchIcon, TrashIcon } from '../../../Icon';
import costService from '../../../services/cost/Cost.service';
import Modal from '../../../components/Moal';

interface CostTableProps {
  // eslint-disable-next-line no-unused-vars
  showMore: (id: string) => void;
}
const CostTable: React.FC<CostTableProps> = ({ showMore }) => {
  const [costs, setCosts] = useState<any[]>([]);
  const [selectedId, setSelectedId] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const fetchData = async () => {
    await costService.getAllCoast().then((res) => {
      setCosts(res.data);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleShowMore = (id: string) => {
    showMore(id);
  };

  const closeModal = () => setModalOpen(false);

  const modal = (id: string) => {
    setModalOpen(true);
    setSelectedId(id);
  };

  const deleteProduct = async (id: string) => {
    try {
      await costService.deleteCostById(id).then(() => {
        toast.success('Prix supprimer correctement');
        fetchData();
        closeModal();
      });
    } catch (e) {
      toast.error('Une erreur est survenu ');
      console.error(e);
    }
  };

  const filteredCosts = costs?.filter(
    (cost) =>
      cost.serviceType.toLowerCase().includes(searchTerm.toLowerCase()) ||
      cost.supplier?.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default sm:px-7.5 xl:pb-1">
      <div className="w-full mx-auto mb-3.5">
        <div className="relative">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <SearchIcon />
          </div>
          <input
            type="search"
            id="default-search"
            onChange={(e) => setSearchTerm(e.target.value)}
            className="block w-full p-4 ps-10 text-sm text-gray-900 border-stroke border rounded-lg bg-gray-50"
            placeholder="Type de service, fournisseur"
            required
          />
          <button
            type="submit"
            className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 font-medium rounded-lg text-sm px-4 py-2">
            Recherche
          </button>
        </div>
      </div>
      <div className="max-w-full overflow-x-auto">
        <table className="w-full table-fixed">
          <thead>
            <tr className="bg-gray-2 text-left dark:bg-meta-4">
              <th className="min-w-[120px] py-4 px-4 font-medium text-black text-center">Prix</th>
              <th className="min-w-[120px] py-4 px-4 font-medium text-black text-center">
                Type de service
              </th>
              <th className="min-w-[120px] py-4 px-4 font-medium text-black text-center">Unité</th>
              <th className="min-w-[120px] py-4 px-4 font-medium text-black text-center">
                Fournisseur
              </th>
              <th className="py-4 px-4 font-medium text-black text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {costs &&
              filteredCosts.map((cost: any, key: number) => (
                <tr key={key} className="">
                  <td className="border-b border-[#eee] py-5 px-4">
                    <h5 className="font-medium text-black dark:text-white text-center">
                      {cost.price}
                    </h5>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4">
                    <p className="text-sm text-center">{cost.serviceType}</p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4">
                    <p className="text-sm text-center">{cost.unit}</p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4">
                    <p className="text-sm text-center">{cost.supplier?.name}</p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4">
                    <div className="flex items-center space-x-3.5 justify-center">
                      <button
                        className="hover:text-primary"
                        onClick={() => handleShowMore(cost.id)}>
                        <EyeIcon />
                      </button>
                      <button className="hover:text-primary" onClick={() => modal(cost.id)}>
                        <TrashIcon />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Modal
        isOpen={modalOpen}
        onClose={closeModal}
        title="Etes vous sur de vouloir supppimer le Prix">
        <div className="flex justify-end gap-x-3 w-full">
          <button
            className="flex justify-center items-center bg-gray p-2 rounded hover:bg-white hover:border"
            onClick={() => closeModal()}>
            Annuler
          </button>
          <button
            className="flex justify-center items-center bg-gray p-2 rounded hover:bg-white hover:border"
            onClick={() => deleteProduct(selectedId ?? '')}>
            Supprimer
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default CostTable;
